import * as React from 'react';
import * as Styled from './styled';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Router } from 'react-router-dom';
import { observer, Provider } from 'mobx-react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { muitheme, theme } from 'common/theme';
import { ThemeProvider } from 'common/styled-components';
// https://material.io/color/#!/?view.left=1&view.right=0&primary.color=FF9800&secondary.color=424242
import rootStore, { RootStore } from 'store/root.store';
import NavBar from 'components/NavBar';
import Home from 'containers/Home';
import Templates from 'containers/Templates';
import Matters from 'containers/Matters';
import Narratives from 'containers/Narratives';
import Settings from 'containers/Settings';
import TimeEntries from 'containers/TimeEntries';
import Timers from 'containers/Timers';
import Login from 'containers/Login/Login';
import ProjectTime from 'containers/ManagementDashboard';
import DraftTime from 'containers/ManagementDashboard/DraftTime';
import RejectionCodeDialog from './containers/ManagementDashboard/RejectionCodeDialog';
import { ApiProvider } from 'common/ApiProvider';
import Spinner from 'components/LoadingSpinner/Spinner';
import TimeKeeperDialog from 'components/TimeKeeperDialog';
import TimeEntryDialog from 'containers/TimeEntryDialog';
import MultipleTimeEntriesDialog from 'containers/MultipleTimeEntriesDialog';
import EULADialog from 'components/EULADialog/EULADialog';
import MoveDateDialog from './containers/MoveDateTimeEntryDialog';
import MergeEntryDialog from './containers/MergeEntryDialog';
import TransferEntryDialog from './containers/TransferEntryDialog';
import CreateNarrativeCodeDialog from 'components/CreateNarrativeCodeDialog';
import {
    Button,
    CircularProgress,
    IconButton,
    Snackbar,
    TextField,
    SnackbarContent
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Settings as LuxonSettings } from 'luxon';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import SplitEntryDialog from './containers/SplitEntryDialog/SplitEntryDialog';
import { FlexDiv } from 'common/flex';
import NewTimerDialog from './containers/NewTimerDialog/NewTimerDialog';
import NavTimerNarrativeDialog from './containers/NavTimerNarrativeDialog';
import { FeaturesProvider } from 'common/FeaturesProvider';
import TimerPopOut from 'components/TimerPopOut/TimerPopOut';
import OfflineEntries from 'components/OfflineEntries/OfflineEntries';
import DateFnsUtils from '@date-io/date-fns';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import GeneralSettings from './containers/GeneralSettings/GeneralSettings';
import EditTimerChunkTimeDialog from 'components/TimerChunkItem/EditTimerChunkTimeDialog';
import TimerPopoutErrorDialog from 'components/TimerPopOut/TimerPopoutErrorDialog';
import HardLogoutDialog from 'components/NavBar/HardLogoutDialog';
import BulkEntriesView from 'containers/BulkUpload/BulkEntriesView';
import { Platform } from './util/Platform';
import { TKProvider } from 'common/TKProvider';
import logger from './logging/logging';
import { withTranslation } from 'react-i18next';
import DashboardList from 'containers/Reports/DashboardList';
import Dashboard from 'containers/Reports/Dashboard';
export const countryTimeZone = require('countries-and-timezones');
const svgLogo = require('images/logodark.svg');
import './App.css';
import { LoadingDiv } from 'containers/Home/Home';

const appStore = rootStore.appStore;

// load in dictionaries!
require('./store/dictionaries');
LuxonSettings.defaultZoneName = 'utc';

export const RootStoreContext = React.createContext<RootStore>(rootStore);

interface Props {
    // tslint:disable-next-line:no-any
    t: any;
}
interface State {
    serverUrl: string;
}

@observer
class App extends React.Component<Props, State> {
    state: State = {
        serverUrl: process && process.env && process.env.NODE_ENV === 'development' 
            ? 'https://dev.epoch.fulcrumgt.com'
            : ''
    }

    componentDidMount() {
        // tslint:disable-next-line:no-any
        rootStore.history.block((location: any) => {
            rootStore.setLocation(location);
            return 'BLOCKED';
        });
        const enableLogging = localStorage.getItem('enableLogging');
        if (enableLogging) {
            if (JSON.parse(enableLogging)) {
                logger.silent = false;
            } else {
                logger.silent = true;
            }
        }
        // tslint:disable-next-line:no-any
        let manifest = (window as any).MANIFEST;
        let appVersion = manifest.version;
        localStorage.setItem('appVersion', JSON.stringify(appVersion));
        logger.info('App has finished loading...');
    }
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        logger.info('Finally, I can see the reason for white screen. See below');
        logger.error(error, errorInfo);
    }
    
    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        appStore.errorText = '';
        this.setState({serverUrl: event.target.value});
    }

    render() {
        const { t } = this.props;
        const {
            initializing,
            isFetchingRegistryKeys,
            authenticated,
            isServerSet,
            connectToServerUrl,
            syncing,
            disableAnimations,
            errorText,
            needsSoftLogin,
            pickersUtilLocale,
            chunkSaveLoader
        } = appStore;
        
        const localStorageServerUrl = localStorage.getItem('serverUrl');
        const serverUrl = localStorageServerUrl ? localStorageServerUrl.replace(/\/api*$/g, '') : this.state.serverUrl;

        muitheme.props = {
            MuiButtonBase: {
                disableRipple: disableAnimations
            }
        };
        if (initializing) {
            return (
                <MuiThemeProvider theme={muitheme}>
                    {Platform.isElectron() ?
                    <Styled.AppContainer>
                        {isServerSet ? (
                            <Spinner />
                        ) : (
                            <FlexDiv fillContainer={true} direction="column">
                                <FlexDiv flex={1}/>
                                <div style={{textAlign: 'center', width: 300, margin: '0 auto'}}>
                                    <img src={svgLogo} height="80px"/>

                                    <br/>
                                    <br/>

                                    <TextField
                                        value={serverUrl}
                                        label={t('app.init.desktop.set_server.field.url.label')}
                                        fullWidth={true}
                                        onChange={this.onChange}
                                        helperText={t(errorText)}
                                        error={errorText !== '' ? true : false}
                                    />
                                    <br/>
                                    <br/>
                                    <Button
                                        color="primary"
                                        variant="raised"
                                        onClick={() => connectToServerUrl(serverUrl)}
                                    >
                                        {t('app.init.desktop.set_server.action.set')}
                                    </Button>

                                    <br/>
                                    <br/>

                                    {isFetchingRegistryKeys && <span>
                                        <CircularProgress style={{width: '16px', height: '16px'}}/>
                                        &nbsp;&nbsp;
                                        {t('app.login.progress.desktop.loading.registry_settings')}
                                    </span>}
                                </div>
                                <FlexDiv flex={1}/>
                            </FlexDiv>
                        )}
                    </Styled.AppContainer> :
                    <Styled.AppContainer>
                        <Spinner />
                    </Styled.AppContainer>
                    }

                    <Provider rootStore={rootStore}>
                        <>
                            <EULADialog/>
                            <TimeKeeperDialog/>

                            {needsSoftLogin && <Styled.Fullscreen><Login /></Styled.Fullscreen>}
                        </>
                    </Provider>
                </MuiThemeProvider>
            )
        }
        
        return (
            <MuiThemeProvider theme={muitheme}>
                <ThemeProvider theme={theme}>
                    <Provider rootStore={rootStore}>
                        <ApiProvider value={rootStore.api}>
                            <FeaturesProvider value={rootStore.appStore!.features}>
                                <DndProvider backend={HTML5Backend}> {/* Provider for Drag and Drop */}
                                <TKProvider value={rootStore.appStore!.currentTimekeeper}>
                                <Router history={rootStore.history}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pickersUtilLocale}>
                                        <Styled.AppContainer disableAnimations={rootStore.appStore.disableAnimations}>
                                            {authenticated ? <>
                                                    <NavBar/>
                                                    <Styled.AppBody>
                                                        <Route
                                                            exact={true}
                                                            path="/"
                                                            render={() => (
                                                                <Redirect to="/home"/>
                                                            )}
                                                        />
                                                        <Route path="/home" component={Home}/>
                                                        <Route path="/templates" component={Templates}/>
                                                        <Route path="/matters" component={Matters}/>
                                                        <Route path="/narratives" component={Narratives}/>
                                                        <Route path="/settings" component={Settings}/>
                                                        <Route path="/timeentries" component={TimeEntries}/>
                                                        <Route path="/timers" component={Timers}/>
                                                        <Route path="/timersPopOut" component={TimerPopOut}/>
                                                        <Route path="/offlineEntries" component={OfflineEntries}/>
                                                        <Route path="/general" component={GeneralSettings}/>
                                                        <Route path="/reports" exact={true} component={DashboardList}/>
                                                        <Route path="/reports/dashboard" exact={true} component={Dashboard}/>
                                                        <Route path="/management/project-time" component={ProjectTime}/>
                                                        <Route path="/management/draft-time" component={DraftTime}/>
                                                    </Styled.AppBody>
                                                    <NavTimerNarrativeDialog/>
                                                    <TimeEntryDialog/>
                                                    <MultipleTimeEntriesDialog allowSaveAsTemplate={false}/>
                                                    <SplitEntryDialog/>
                                                    <MoveDateDialog/>
                                                    <MergeEntryDialog/>
                                                    <TransferEntryDialog/>
                                                    <NewTimerDialog/>
                                                    <ConfirmationDialog/>
                                                    <CreateNarrativeCodeDialog/>
                                                    <EditTimerChunkTimeDialog/>
                                                    <TimerPopoutErrorDialog/>
                                                    <HardLogoutDialog/>
                                                    <RejectionCodeDialog/>
                                                    <BulkEntriesView/>
                                                </> :
                                                <Route path="*" component={Login}/>
                                            }

                                            <CssBaseline/>

                                        </Styled.AppContainer>
                                    </MuiPickersUtilsProvider>
                                </Router>
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    open={rootStore.snackbarStore.snackbarOpen}
                                    autoHideDuration={3200}
                                    onClose={rootStore.snackbarStore.closeSnackbar}
                                    action={[
                                        <IconButton
                                            key="close"
                                            aria-label={t('close')}
                                            color="inherit"
                                            onClick={rootStore.snackbarStore.closeSnackbar}
                                        >
                                            <Close/>
                                        </IconButton>
                                    ]}
                                    TransitionProps={{
                                        appear: !disableAnimations
                                    }}
                                >
                                    <SnackbarContent
                                        style={{
                                            backgroundColor: rootStore.snackbarStore.isError ? '#ef4e4e' : '',
                                            color: rootStore.snackbarStore.isError ? 'white' : '',
                                        }}
                                        message={
                                            <span id="message-id">
                                                    {t(rootStore.snackbarStore.transaltionKey, rootStore.snackbarStore.translationOptions)}
                                            </span>
                                        }
                                    />
   
                                </Snackbar>
                                {syncing && LoadingDiv()}
                                {chunkSaveLoader && LoadingDiv()}
                                    {needsSoftLogin && <Styled.Fullscreen>
                                        <Login />
                                    </Styled.Fullscreen>}
                                    </TKProvider>
                                </DndProvider>
                            </FeaturesProvider>
                        </ApiProvider>
                    </Provider>
                </ThemeProvider>
            </MuiThemeProvider>
        );
    }
}

export default withTranslation(['common'])(App);