import { action, computed, observable, makeObservable } from 'mobx';
import { loadable } from '@fulcrumgt/mobx-store-utils';
import { DateTime } from 'luxon';
import XLSX from 'xlsx';
import { t } from 'i18next';
import BaseStore from 'store/base.store';
import { RootStore } from 'store/root.store';
import {
    BulkUploadData,
    BulkUploadEntry,
    BulkUploadRawData,
    BulkUploadObject,
    BulkUploadEntryError,
    FIELDS
} from '../api/types/types';
import { getDateFormat } from 'util/date';
import { FIELDS_PROPS } from 'containers/BulkUpload/BulkEntriesView';

export default class BulkUploadStore extends BaseStore {
    @observable bulkEntries: BulkUploadObject[] = [];
    @observable filteredBulkEntries: BulkUploadObject[] = [];
    @observable invalidRows: Set<string> = new Set();
    @observable expandedIds: Set<string> = new Set();
    @observable isBulkEntriesViewOpen: boolean = false;
    @observable loading: boolean = false;
    @observable showOnlyInvalidRows: boolean = false;
    @observable fields: string[] = [];
    refreshTimeEntriesPage: boolean = false;

    // pagination values
    @observable currentPage: number = 1;
    @observable entriesPerPage: number = 25;
    @observable startIndex: number = 1;
    @observable endIndex: number = 25;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    @action.bound
    setFieldsKeys() {
        this.fields = Object.keys(FIELDS_PROPS).filter(f => (f !== FIELDS.ROW && f !== FIELDS.NARRATIVE));
        // if workLocale used, remove workLocale form current position and add it as 4th element after matter
        const index = this.fields.findIndex(fi => fi === FIELDS.WORK_LOCALE);
        if (index > -1) {
            this.fields.splice(index, 1);
            this.fields.splice(3, 0, FIELDS.WORK_LOCALE);
        }
    }

    @action.bound
    resetBulkUploadStore() {
        this.bulkEntries = [];
        this.filteredBulkEntries = [];
        this.invalidRows.clear();
        this.expandedIds.clear();
        this.showOnlyInvalidRows = false;
        // reset Pagination values
        this.startIndex = 1;
        this.endIndex = 25;
        this.setEntriesPerPage(25);
        this.setPageNum(1);
    }

    @loadable()
    @action.bound
    async downloadBulkTemplate() {
        this.rootStore.snackbarStore.triggerSnackbar('header.action.bulk_download.started', { ns: 'timeentries' });
        this.rootStore.api.TimeEntry.downloadBulkEntriesTemplate();
    }

    @loadable()
    @action.bound
    async init(f: File) {
        let jsonData = await this.convertDataToJson(f);
        if (jsonData.length > 0) {
            const keys = await this.getHeaderKeyMapping(Object.keys(jsonData[0]));
            if (keys) {
                this.constructData(jsonData, keys);
            }
        } else {
            this.rootStore.snackbarStore.triggerSnackbar(
                t('header.action.bulk_upload.warning.empty_file', { ns: 'timeentries' })
            );
            this.loading = false;
        }
    }

    @loadable()
    @action.bound
    convertDataToJson(f: File): Promise<BulkUploadRawData[]> {
        this.loading = true;
        const fileName = f.name;
        let reader = new FileReader();
        reader.readAsArrayBuffer(f);
        return new Promise((resolve, reject) => {
            reader.onload = (e) => {
                let jsonData: BulkUploadRawData[] = [];
                try {
                    const data = new Uint8Array(e.target!.result as ArrayBuffer);
                    const wb = XLSX.read(data, { type: 'array' });
                    const ws = wb.Sheets[wb.SheetNames[0]];
                    jsonData = XLSX.utils.sheet_to_json(ws, { defval: '', raw: false });
                } catch (e) {
                    this.rootStore.snackbarStore.triggerSnackbar(
                        'header.action.bulk_upload.error.wrong_format', { fileName, ns: 'timeentries' }, true
                    );
                }
                resolve(jsonData);
            }
            reader.onerror = (e) => {
                this.rootStore.snackbarStore.triggerSnackbar(
                    'header.action.bulk_upload.error.file_read', { fileName, ns: 'timeentries' }, true
                );
                this.loading = false;
                reject(e);
            }
        });
    }

    // generate keys form labels - internationalization labels and file header have to match
    getHeaderKeyMapping = async (header: string[]) => {
        const mappedHeaderKeys: {[key: string]: string} = {};
        const matterLabel = this.rootStore.appStore.features.EpochConfigMatterLabel;
        let missingFields = false;
        const keys = Object.keys(FIELDS_PROPS).filter(k => k !== FIELDS.ROW);
        keys.forEach(key => {
            if (missingFields) { return; } // if any field is missing then return for rest.
            const fieldName = t(`bulk_upload.view.field.${FIELDS_PROPS[key].labelKey}`, { ns: 'timeentries', matterLabel });
            const mappedKey = header.find(h =>
                h.toLowerCase().includes(fieldName.toLowerCase())
            );
            if (mappedKey) {
                mappedHeaderKeys[key] = mappedKey;
            } else {
                missingFields = true;
            }
        });
        if (missingFields) {
            this.rootStore.snackbarStore.triggerSnackbar(
                t('header.action.bulk_upload.error.wrong_format', { ns: 'timeentries' }), undefined, true
            );
            this.loading = false;
            return null;
        }
        return mappedHeaderKeys;
    }

    // @loadable()
    @action.bound
    constructData(data: BulkUploadRawData[], keys: {[key: string]: string}) {
        try {
            this.bulkEntries = data.map(ent => {
                const entry = ({
                    [FIELDS.ROW]: ent.__rowNum__ + 1, // add one because header row is not included in converstion
                    [FIELDS.WORK_DATE]: ent[keys[FIELDS.WORK_DATE] as keyof BulkUploadData]!,
                    [FIELDS.DURATION]: ent[keys[FIELDS.DURATION] as keyof BulkUploadData]!,
                    [FIELDS.MATTER_NUMBER]: ent[keys[FIELDS.MATTER_NUMBER] as keyof BulkUploadData]!,
                    [FIELDS.WORK_LOCALE]: ent[keys[FIELDS.WORK_LOCALE] as keyof BulkUploadData],
                    [FIELDS.PHASE]: ent[keys[FIELDS.PHASE] as keyof BulkUploadData]!,
                    [FIELDS.TASK]: ent[keys[FIELDS.TASK] as keyof BulkUploadData]!,
                    [FIELDS.ACTIVITY]: ent[keys[FIELDS.ACTIVITY] as keyof BulkUploadData]!,
                    [FIELDS.FF_TASK]: ent[keys[FIELDS.FF_TASK] as keyof BulkUploadData]!,
                    [FIELDS.FF_ACT]: ent[keys[FIELDS.FF_ACT] as keyof BulkUploadData]!,
                    [FIELDS.ACTION_CODE]: ent[keys[FIELDS.ACTION_CODE] as keyof BulkUploadData],
                    [FIELDS.BILLABLE]: ent[keys[FIELDS.BILLABLE] as keyof BulkUploadData],
                    [FIELDS.NARRATIVE]: ent[keys[FIELDS.NARRATIVE] as keyof BulkUploadData]!
                });
                const errors = this.initialValidation(entry);
                return { entry, errors };
            });
            this.filteredBulkEntries = [...this.bulkEntries];
            this.loadBulkEntriesView();
        } finally {
            this.loading = false; 
        }
    }

    initialValidation(entry: BulkUploadEntry): BulkUploadEntryError {
        const errors: BulkUploadEntryError = {};
        const requiredFields: string[] = [FIELDS.MATTER_NUMBER, FIELDS.NARRATIVE];
        requiredFields.forEach((field: keyof BulkUploadEntry) => {
            if (entry[field]!.trim() === '') {
                errors[field] = [t('bulk_upload.view.field.validation.required', { ns: 'timeentries' })];
            }
        });
        const dur = Number(entry[FIELDS.DURATION]);
        if (isNaN(dur) || dur < 0 || dur > 24) {
            errors[FIELDS.DURATION] = [t('bulk_upload.view.field.validation.duration', { ns: 'timeentries' })];
        }
        const date = DateTime.fromISO(entry[FIELDS.WORK_DATE]);
        if (!date.isValid) {
            errors[FIELDS.WORK_DATE] = [
                `${t('bulk_upload.view.field.validation.date', { ns: 'timeentries' })}: ${getDateFormat()}`
            ];
        }
        if (JSON.stringify(errors) !== '{}') {
            this.addToInvalidRows(entry[FIELDS.ROW]);
        }
        return errors;
    }

    @action.bound
    addToInvalidRows(rowNum: string) {
        this.invalidRows.add(rowNum);
    }

    @action.bound
    removeFromInvalidRows(rowNum: string) {
        this.invalidRows.delete(rowNum);
    }

    @action.bound
    updateExpandedIds(row: string) {
        if (this.expandedIds.has(row)) {
            this.expandedIds.delete(row);
        } else {
            this.expandedIds.add(row);
        }
    }

    @action.bound
    loadBulkEntriesView() {
        this.isBulkEntriesViewOpen = true;
    }

    @action.bound
    closeBulkEntriesView() {
        if (this.refreshTimeEntriesPage) {
            this.rootStore.timeEntryStore.getEntries();
            this.rootStore.timeEntryStore.setPageNum(1);
            this.refreshTimeEntriesPage = false;
        }
        this.isBulkEntriesViewOpen = false;
        this.resetBulkUploadStore();
    }

    @loadable()
    @action.bound
    async submitBulkEntries() {
        this.loading = true;
        const entries = this.bulkEntries.map(el => el.entry);
        const response = await this.rootStore.api.TimeEntry.uploadTimeEntries(entries);
        this.refreshTimeEntriesPage = this.refreshTimeEntriesPage || response.some(res => !res.status.failed);
        this.resetBulkUploadStore();

        this.bulkEntries = response.filter(r => r.status.failed)
            .map(e => {
                this.invalidRows.add(e.object[FIELDS.ROW]);
                return {
                    entry: e.object, // TODO
                    errors: e.status.errors || {error: [e.status.message!]}
                };
        });

        this.filteredBulkEntries = [...this.bulkEntries]; 
        const failedCount = this.bulkEntries.length;
        if (failedCount > 0) {
            this.rootStore.snackbarStore.triggerSnackbar(
                `bulk_upload.view.header.action.submit.failed.${failedCount === 1 ? 'one' : 'multi'}`,
                {
                    failedCount,
                    totalSubmitted: response.length,
                    ns: 'timeentries'
                },
                true
            );
        } else {
            this.closeBulkEntriesView();
            this.rootStore.snackbarStore.triggerSnackbar(
                'bulk_upload.view.header.action.submit.success', { ns: 'timeentries' }
            );
        }
        this.loading = false;
    }

    @action.bound
    toggleOnlyShowInvalidRows() {
        this.showOnlyInvalidRows = !this.showOnlyInvalidRows;
        if (this.showOnlyInvalidRows) {
            this.filteredBulkEntries = this.bulkEntries.filter(obj => JSON.stringify(obj.errors) !== '{}');
        } else {
            this.filteredBulkEntries = [...this.bulkEntries];
        }
    }

    @action.bound
    bulkEntryOnChange(data: BulkUploadObject) {
        const index = this.bulkEntries.findIndex(obj => obj.entry[FIELDS.ROW] === data.entry[FIELDS.ROW]);
        this.bulkEntries.splice(index, 1, data);
        const ind = this.filteredBulkEntries.findIndex(obj => obj.entry[FIELDS.ROW] === data.entry[FIELDS.ROW]);
        this.filteredBulkEntries.splice(ind, 1, data);
    }

    @action.bound
    async removeBulkEntry(row: string) {
        const confirm = await this.rootStore.confirmStore.confirm('dialog.confirm.message.delete');
        if (!confirm) { return; }

        const index = this.bulkEntries.findIndex(obj => obj.entry[FIELDS.ROW] === row);
        this.bulkEntries.splice(index, 1);
        if (this.bulkEntries.length === 0) {
            this.closeBulkEntriesView();
            return;
        }
        
        const ind = this.filteredBulkEntries.findIndex(obj => obj.entry[FIELDS.ROW] === row);
        this.filteredBulkEntries.splice(ind, 1);
        if (this.filteredBulkEntries.length === 0) {
            this.showOnlyInvalidRows = false;
            this.filteredBulkEntries = [...this.bulkEntries];
        }

        this.removeFromInvalidRows(row);
        this.expandedIds.delete(row);
    }

    @action.bound
    setEntriesPerPage(n: number) {
        this.entriesPerPage = n;
    }

    @action.bound
    setPageNum(n: number) {
        this.currentPage = n;
        this.startIndex = this.currentPage === 1 ? 1 : (this.currentPage - 1) * this.entriesPerPage + 1;
        this.endIndex = this.currentPage * this.entriesPerPage;
    }

    @computed get paginatedBulkEntries() {
        return this.filteredBulkEntries.slice(this.startIndex - 1, this.endIndex);
    }
}