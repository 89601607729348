import { Typography, Paper, TextField, List } from '@material-ui/core';
import styled from 'common/styled-components';

export const HeaderTitle = styled(Typography)`
    flex: auto;
    text-align: center;
`;

export const SubHeaderContainer = styled.div`
    display: flex;
    height: 52px;
    justify-content: space-between;
    margin: 0 30px;
`;

export const SubHeaderText = styled.div`
    display: flex;
    gap: 40px;
    padding: 18px 0 0 63px;
`;

export const ListContainer = styled(List)`
    padding-top: 0 !important;
`;

export const ItemContainer = styled(Paper)<{error: boolean}>`
    border-left: 6px solid ${({error, theme}) => error ? theme.summary.red : 'transparent'};
    transition: .2s;
    margin: 5px;
`;

export const GeneralError = styled.div`
    margin: 6px;
    padding-left: 46px;
    color: ${({theme}) => theme.error};
`;

export const FieldsContainer = styled.div`
    display: flex;
    gap: 6px;
    margin: 6px;
`;

export const Field = styled(TextField)<{minWidth?: string, width?: string}>`
    ${props => props.width ? `width: ${props.width} !important;` : ''}
    ${props => props.minWidth ? `min-width: ${props.minWidth} !important;` : ''}
`;

export const ActionContainer = styled.div`
    display: flex;
`;

export const Narrative = styled.div`
    margin: 6px;
    padding-left: 46px;
`;