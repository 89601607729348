import styled from 'styled-components';
import { IconButton, Grid, Typography } from '@material-ui/core';

export const StyledGrid = styled(Grid)`
    padding: 5px 5px 10px 5px !important;
`;

export const TimeEntryList = styled.div`
    position: relative;
    height: 100%;
    overflow-y: auto;
    padding: 5px 5px 100px;
`;

export const NoEntry = styled.div`
    margin-top: 100px;
    color: grey;
    font-size: 30px;
    text-align: center;
`;

export const PrintIcons = styled.div`
    text-align: center;
`;

export const TimeContainer = styled.div`
    flex: 1;
    display: flex;
    text-align: center;
    padding: 14px 40px;
    font-size: 14px;
`;

export const TimeItem = styled.div`
    flex: auto;
`;

// // Delegations Page Formatting
// export const Container = styled.div`
//     height: 100%;
//     display: flex;
//     flex-direction: column;
// `;

// export const Header = styled.div`
//     height: 64px;
//     background-color: ${props => props.theme.primary.main};
//     display: flex;
//     align-items: center;
// `;

// export const SearchContainer = styled.div`
//     flex: 1;
//     padding: 12px 12px 12px 24px;
//     display: flex;
//     align-items: flex-end;
// `;

// export const Title = styled(Typography)`
//     flex: 1;
//     text-align: center;
// `;

// export const NewActions = styled.div`
//     flex: 1;
//     text-align: right;
// `;

// export const New = styled(IconButton)`
//     float: right;
//     && {
//         margin-right: 24px;
//     }
// `;

// export const ContentContainer = styled.div`
//     display: flex;
//     flex-direction: row;
//     width: 100%;
//     height: 100%;  
// `;