import { Narrative } from 'api/types/types'
import DialogRootStore from './dialog.root.store';
import { observable, action, makeObservable } from 'mobx';
import { loadable } from '@fulcrumgt/mobx-store-utils';
import { RootStore } from 'store/root.store';

export default class CreateNarrativeCodeDialogStore extends DialogRootStore<void, void> {
    @observable narrativeKey: string = '';
    @observable narrativeReplacement: string = '';
    @observable invalid: Record<string, string | boolean> = {};
    @observable loading = false;
    narratives: Narrative[] = [];

    constructor(rootStore: RootStore) {
        super(rootStore);
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @action
    setNarrativeKey = (key: string) => {
        this.narrativeKey = key;
        this.resetInvalid();
    }
    
    @action
    setNarrativeReplacement = (rep: string) => {
        this.narrativeReplacement = rep;
        this.resetInvalid();
    }
    
    @action
    validate() {
        this.invalid.narrativeReplacement = this.narrativeReplacement.trim() === '' ?
            'narrative_field.dialog.create.validation.text.required' : false;
        const regex = /^[a-zA-Z0-9]+([ -][a-zA-Z0-9]*)*$/;
        this.invalid.narrativeKey = 
            this.narrativeKey === '' ? 'narrative_field.dialog.create.validation.code.required' :
            this.narrativeKey.length < 2 ? 'narrative_field.dialog.create.validation.code.length' :
            ( this.narrativeKey.indexOf(' ') > -1 ? 'narrative_field.dialog.create.validation.code.space' :
            this.loading ? 'narrative_field.dialog.create.validation.code.narratives_not_loaded' :
            (this.isKeyDuplicate(this.narrativeKey) ? 'narrative_field.dialog.create.validation.code.duplicate' :
            this.narrativeKey.startsWith('-') ? 'narrative_field.dialog.create.validation.code.starts_with_hyphen' :
            !regex.test(this.narrativeKey) ? 'narrative_field.dialog.create.validation.code.invalid_characters' :
            false));
        this.invalid.narrativeKey = this.invalid.narrativeKey ?
            JSON.stringify({ namespace: this.invalid.narrativeKey }) : false;
        
        return !this.invalid.narrativeReplacement && !this.invalid.narrativeKey;
    }

    @action validateGlobalDuplicate(key: string): void {
        let obj = this.narratives.find(n => n.key.toLowerCase() === key.toLowerCase());
        if (obj && obj.global) {
            this.invalid.narrativeKey = JSON.stringify({
                namespace: 'narrative_field.dialog.create.validation.code.override_global',
                code: obj.key
            });
        }
    }

    async onOpen() {
        this.resetInvalid();
        this.fetchNarratives();
    }

    @action.bound
    resetInvalid() {
        this.invalid = { 
            narrativeReplacement: false,
            narrativeKey: false
        };
    }

    @loadable()
    @action.bound
    async fetchNarratives() {
        this.loading = true;
        try {
            this.narratives = await this.rootStore.api.Narrative.getAllNarratives();
        } catch (e) {
            this.cancel();
            throw e;
        } finally {
            this.loading = false;
        }
    }

    isKeyDuplicate(key: string): boolean {
        return this.narratives.findIndex(n => (n.key.toLowerCase() === key.toLowerCase()) && !n.global) > -1;
    }
}