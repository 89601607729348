import AppStore from 'store/app.store';
import { syncHistoryWithStore } from 'mobx-react-router';
import NavigationStore from 'store/navigation.store';
import HomeStore from 'store/home.store';
import RootAPI from 'api/interfaces/RootAPI';
import WebRootImpl from 'api/implementations/web/Root.impl';
import TimekeeperDialogStore from 'store/timekeeper.dialog.store';
import EULADialogStore from 'store/eula.dialog.store';
import { action, observable, computed, makeObservable, configure } from 'mobx';
import TemplateStore from 'store/template.store';
import SnackbarStore from 'store/snackbar.store';
import TimeEntryDialogStore from 'store/timeentry.dialog.store';
import MultipleTimeEntriesDialogStore from 'store/multipleTimeentries.dialog.store';
import NarrativeStore from 'store/narrative.store';
import NarrativeCodesDialogStore from 'store/narrativeCodes.dialog.store';
import CreateNarrativeCodeDialogStore from 'store/createNarrativeCodeDialog.store';
import MoveDateDialogStore from 'store/moveDate.dialog.store';
import TimeEntryStore from 'store/timeentry.store';
import ManagementDashboardStore from './managementDashboard.store';
import RejectionCodeDialogStore from './rejectionCodeDialog.store';
import SplitentryDialogStore from 'store/splitEntry.dialog.store';
import ElectronRootImpl from 'api/implementations/electron/Root.impl';
import MergeEntryDialogStore from './mergeEntry.dialog.store';
import TimerStore from './Timer/timer.store';
import CustomDictionaryStore from 'store/customDictionary.store';
import TimerNewDialogStore from 'store/Timer/timer.new.dialog.store';
import NarrativeDialogStore from 'store/narrative.dialog.store';
import MattersStore from 'store/matters.store';
import TimeCastSettingsStore from 'store/timecastSettings.store'
import TransferEntryDialogStore from './transferEntry.dialog.store';
import { Platform } from '../util/Platform'
import PendingItemStore from 'store/pendingItem.store';
import TimerPanelItemStore from 'store/Timer/timerPanelItem.store';
import EditTimerTimeDialogStore from 'store/Timer/edit.timer.time.dialog.store';
import TimerPopoutErrorMessageDialogStore from 'store/Timer/timer.popout.error.message.dialog.store';
import HardLogoutStore from 'store/hardLogout.store';
import ConfirmStore from 'store/confirm.store';
import GridViewStore from './gridView.store';
import logger from '../logging/logging';
import ReportsStore from 'store/reports.store';
import BulkUploadStore from 'store/bulkUpload.store';

configure({
    enforceActions: 'never',
});

export class RootStore {
    @observable appStore: AppStore;
    @observable routerStore: NavigationStore;
    @observable homeStore: HomeStore;
    @observable timekeeperDialogStore: TimekeeperDialogStore;
    @observable narrativeCodesDialogStore: NarrativeCodesDialogStore;
    @observable eulaDialogStore: EULADialogStore;
    @observable createNarrativeCodeDialogStore: CreateNarrativeCodeDialogStore;
    @observable timeEntryDialogStore: TimeEntryDialogStore;
    @observable multipleTimeEntriesDialogStore: MultipleTimeEntriesDialogStore;
    @observable moveDateDialogStore: MoveDateDialogStore;
    @observable timeEntryStore: TimeEntryStore;
    @observable managementDashboardStore: ManagementDashboardStore;
    @observable rejectionCodeDialogStore: RejectionCodeDialogStore;
    @observable splitEntryDialogStore: SplitentryDialogStore;
    @observable timerStore: TimerStore;
    @observable mergeEntryDialogStore: MergeEntryDialogStore;
    @observable newTimerDialogStore: TimerNewDialogStore;
    @observable narrativeDialogStore: NarrativeDialogStore;
    @observable transferEntryDialogStore: TransferEntryDialogStore;
    @observable pendingItemsStore: PendingItemStore;
    @observable timerPanelItemStore: TimerPanelItemStore;
    @observable editTimerChunkTimeDialogStore: EditTimerTimeDialogStore;
    @observable timerPopoutErrorDialogStore: TimerPopoutErrorMessageDialogStore;
    @observable hardLogoutStore: HardLogoutStore;
    // tslint:disable-next-line:no-any
    history: any;
    api: RootAPI;
    @observable templateStore: TemplateStore;
    @observable gridViewStore: GridViewStore;
    @observable snackbarStore: SnackbarStore;
    @observable narrativeStore: NarrativeStore;
    @observable customDictionaryStore: CustomDictionaryStore;
    @observable timecastSettingsStore: TimeCastSettingsStore;
    @observable mattersStore: MattersStore;
    @observable confirmStore: ConfirmStore;
    @observable reportsStore: ReportsStore;
    @observable pendingLocation?: Location;
    @observable bulkUploadStore: BulkUploadStore;
    
    constructor() {
        makeObservable(this);
        if (Platform.isElectron()) {
            this.api = new ElectronRootImpl({
                isOnline: () => this.appStore && this.appStore.online
            });
        } else {
            this.api = new WebRootImpl();
        }
        this.appStore = new AppStore(this);
        this.loadStores();
        this.api.Session.setReinitializeHandler(this.reinitializeHandler);
    }
    
    @action.bound loadStores() {
        this.api.Session.setOnlineStatusChangeHandler(this.appStore.setOnline)
        this.routerStore = new NavigationStore(this);
        this.homeStore = new HomeStore(this);
        this.timekeeperDialogStore = new TimekeeperDialogStore(this);
        this.narrativeDialogStore = new NarrativeDialogStore(this);
        this.templateStore = new TemplateStore(this);
        this.snackbarStore = new SnackbarStore();
        this.timeEntryDialogStore = new TimeEntryDialogStore(this);
        this.multipleTimeEntriesDialogStore = new MultipleTimeEntriesDialogStore(this);
        this.narrativeCodesDialogStore = new NarrativeCodesDialogStore(this);
        this.createNarrativeCodeDialogStore = new CreateNarrativeCodeDialogStore(this);
        this.narrativeStore = new NarrativeStore(this);
        this.eulaDialogStore = new EULADialogStore(this);
        this.moveDateDialogStore = new MoveDateDialogStore(this);
        this.timeEntryStore = new TimeEntryStore(this);
        this.managementDashboardStore = new ManagementDashboardStore(this);
        this.rejectionCodeDialogStore = new RejectionCodeDialogStore(this);
        this.timerStore = new TimerStore(this);
        this.newTimerDialogStore = new TimerNewDialogStore(this);
        this.splitEntryDialogStore = new SplitentryDialogStore(this);
        this.mergeEntryDialogStore = new MergeEntryDialogStore(this);
        this.customDictionaryStore = new CustomDictionaryStore(this);
        this.timecastSettingsStore = new TimeCastSettingsStore(this);
        this.transferEntryDialogStore = new TransferEntryDialogStore(this);
        this.mattersStore = new MattersStore(this);
        this.pendingItemsStore = new PendingItemStore(this);
        this.timerPanelItemStore = new TimerPanelItemStore(this);
        this.editTimerChunkTimeDialogStore = new EditTimerTimeDialogStore(this);
        this.timerPopoutErrorDialogStore = new TimerPopoutErrorMessageDialogStore(this);
        this.hardLogoutStore = new HardLogoutStore(this);
        this.confirmStore = new ConfirmStore(this);
        this.gridViewStore = new GridViewStore(this);
        this.reportsStore = new ReportsStore(this);
        this.bulkUploadStore = new BulkUploadStore(this);
        this.history = syncHistoryWithStore(
            this.routerStore.createHistory(),
            this.routerStore
        );
    }
    @action
    setLocation(l: Location) {
        this.pendingLocation = l;
    }
    @computed get dirty(): boolean {
        return this.templateStore.dirty ||
        this.narrativeStore.dirty ||
        this.homeStore.dirty || 
        this.timeEntryStore.dirty || 
        this.customDictionaryStore.dirty ||
        this.timerStore.dirty || 
        this.timecastSettingsStore.dirty ||
        this.gridViewStore.dirty;
    }

    @action
    resetForms = () => {
        if (this.templateStore.dirty) {
            this.templateStore.restoreTemplate();
        }
        if (this.narrativeStore.dirty) {
            this.narrativeStore.restoreNarrative();
        }
        if (this.homeStore.dirty) {
            this.homeStore.resetDirty();
        }
        if (this.gridViewStore.dirty) {
            this.gridViewStore.reset();
        }
        if (this.timeEntryStore.dirty) {
            this.timeEntryStore.resetDirty();
        }
        if (this.customDictionaryStore.dirty) {
            this.customDictionaryStore.resetWords();
        }
        if (this.timerStore.dirty) {
            this.timerStore.resetDirty();
        }
        if (this.timecastSettingsStore.dirty) { 
            this.timecastSettingsStore.reset()
        }
        if (this.routerStore.location.pathname === `/home`) {
            this.homeStore.resetDates();
        }
    }

    canIChangeScope = async (): Promise <boolean> => {
        if (!this.dirty) {
            return true;
        }
        
        const res = await this.confirmStore.confirm('dialog.confirm.message.unsaved_changes');
       
        if (res) {
            this.resetForms();
            return true;
        } else {
            return false;
        }
    }
    @action reinitializeHandler = (attemptSoftLogin: boolean) => {
        if (attemptSoftLogin) {
            this.appStore.needsSoftLogin = true
            this.appStore.authenticated = false
        } else {
            // this.appStore.initializing = true;
            this.appStore.authenticated = false;
            this.homeStore.handlerDestructor();
            this.timeEntryStore.handlerDestructor();
            this.templateStore.handlerDestructor();
            this.loadStores();
            // this.appStore!.initialize(false);
        }
    }

    @action.bound
    onError(err: string) {
        // this.snackbarStore.error(`${err}`);
        this.snackbarStore.isError = true;
        this.snackbarStore.triggerSnackbar(err);
        logger.error(err);
    };
}

export default new RootStore();