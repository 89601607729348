import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    LinearProgress
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import NarrativeStore from 'store/narrative.store'
import { Features, Narrative } from 'api/types/types'
import CreateNarrativeCodeDialogStore from 'store/createNarrativeCodeDialog.store';
import NarrativeField from 'components/NarrativeField';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { withTranslation } from 'react-i18next';

interface Props {
    createNarrativeCodeDialogStore?: CreateNarrativeCodeDialogStore;
    narrativeStore?: NarrativeStore;
    // tslint:disable-next-line:no-any
    t: any;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        narrativeStore: rootStore.narrativeStore,
        createNarrativeCodeDialogStore: rootStore.createNarrativeCodeDialogStore
    };
})
@observer
class CreateNarrativeCodeDialog extends React.Component<Props> {
    onSave = () => {
        const store = this.props.createNarrativeCodeDialogStore!;
        if (store.validate()) {
            this.props.narrativeStore!.addNarrative(
                new Narrative(store.narrativeKey, store.narrativeReplacement), false
            );
            store.resolveAndClose();
        }
    }
    
    onBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const store = this.props.createNarrativeCodeDialogStore!;
        if (e.target.value) {
            store.validateGlobalDuplicate(e.target.value);
        }
    }

    render() {
        const { t, createNarrativeCodeDialogStore } = this.props;
        const {
            isOpen,
            cancel,
            loading,
            setNarrativeKey,
            narrativeKey,
            invalid,
            setNarrativeReplacement,
            narrativeReplacement
        } = createNarrativeCodeDialogStore!;

        let narrativeKeyValidator = typeof invalid.narrativeKey === 'string' ?
            JSON.parse(invalid.narrativeKey) : undefined;
        let narrativeReplacementValidator = invalid.narrativeReplacement;
        
        return (
            <FeaturesConsumer>
                { (features: Features) =>
                <>
                    <Dialog
                        open={isOpen}
                        onClose={cancel}
                        disableBackdropClick={true}
                        disableEscapeKeyDown={true}
                    >
                        <DialogTitle>
                            <div>{t('narrative_field.dialog.create.title')}</div>
                            {loading && <LinearProgress/>}
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                fullWidth={true}
                                label={t('narrative_field.dialog.create.field.code')}
                                onChange={e => setNarrativeKey(e.target.value)}
                                value={narrativeKey}
                                error={!!narrativeKeyValidator}
                                helperText={
                                    narrativeKeyValidator ?
                                        t(narrativeKeyValidator.namespace, { code: narrativeKeyValidator.code })
                                    :
                                        undefined
                                }
                                onBlur={this.onBlur}
                                inputProps={{
                                    maxLength: 10
                                }}
                            />
                            <NarrativeField
                                disableNarrativeContextMenuActions={true}
                                onChange={setNarrativeReplacement}
                                placeholder={t('narrative_field.dialog.create.field.text')}
                                value={narrativeReplacement}
                                error={!!narrativeReplacementValidator}
                                helperText={narrativeReplacementValidator ? t(narrativeReplacementValidator) : undefined}
                                maxLength={features.EpochConfigNarrativesMaximumChars}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.onSave} color="primary">
                                {t('narrative_field.dialog.create.action.create')}
                            </Button>
                            <Button onClick={cancel} color="primary">
                                {t('cancel', { ns: 'common' })}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
                }      
            </FeaturesConsumer>    
        );
    }
}

export default withTranslation(['narratives', 'common'])(CreateNarrativeCodeDialog);