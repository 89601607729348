import { action, computed, observable, makeObservable } from 'mobx';
import { loadable } from '@fulcrumgt/mobx-store-utils';
import { Narrative } from 'api/types/types';
import BaseStore from 'store/base.store';
import { removeListItem, setListItem } from 'util/array';
import { RootStore } from './root.store';
import Mutex from '../api/util';
import { debounce } from 'typescript-debounce-decorator';
import logger from '../logging/logging';

export default class NarrativeStore extends BaseStore {
    @observable narratives: Narrative[] = [];
    @observable selectedNarrative: Narrative;
    @observable searchText: string = '';
    @observable dirty: boolean = false;
    @observable invalid: Record<string, string | boolean> = {};
    originalNarrative: Narrative;
    @observable loading: boolean = false;
    @observable currentOffset: number = 0;
    caughtEmAll: boolean = false;
    loadMutex = new Mutex();
    limit: number = 50;
    allNarratives: Narrative[] = [];
    fetchingNarratives: boolean = false;

    handlerDestructor: () => void;
    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
        this.initializeHandler();
    }
    initializeHandler = () => {
        this.handlerDestructor = this.rootStore.api.Narrative.registerReciever(this.recieveNarratives);
    }

    @computed get maxNarrativeLength() {
        return this.rootStore!.appStore!.features!.EpochConfigNarrativesMaximumChars;
    }

    @action recieveNarratives = (narratives: Narrative[]) => {
        narratives.forEach(n => {
            if (n.deleted) {
                this.narratives = removeListItem(this.narratives, n.id!);
                this.resetInvalid();
                if (this.selectedNarrative && this.selectedNarrative.id === n.id) {
                    this.selectedNarrative = new Narrative();
                    this.originalNarrative = Object.assign(new Narrative(), this.selectedNarrative);
                    this.dirty = false;
                    this.rootStore.routerStore.push(`/narratives/new`);
                }
                return;
            }
            if (this.searchTextInclude(n.key) || this.searchTextInclude(n.replacement)) {
                // TODO: investigate the root cause problem for duplication entries
                const idx = this.narratives.findIndex(nar => nar.key === n.key && nar.id !== n.id);
                const localNarratives = this.narratives.filter(nar => !nar.global);
                const isAllLocalNarrativesLoaded = localNarratives.length < this.narratives.length;
                const lastLoadedNarrative = localNarratives.length > 0 ? localNarratives[localNarratives.length - 1] : null;
                // check if narrative not in list AND (all local narratives loaded OR  before last narrative in list) "fix for Desktop"
                if (idx === -1 && (isAllLocalNarrativesLoaded || this.caughtEmAll || n.key.localeCompare(lastLoadedNarrative!.key) < 0)) {
                    let newLocal = this.narratives.slice();
                    setListItem(newLocal, n);
                    this.narratives = newLocal;
                    this.sortNarratives();
                }
            } else {
                this.narratives = removeListItem(this.narratives, n.id!);
            }
        })
        if (this.currentOffset === 0) {
            this.narratives = [];
        }
    }

    @loadable()
    @action.bound
    async loadNarrative(id: number) {
        this.selectedNarrative = await this.rootStore.api.Narrative.getNarrative(id);
        this.originalNarrative = Object.assign(new Narrative(), this.selectedNarrative);
    };

    @action changeSelectedNarrative = async (id: number) => {
        if (!this.selectedNarrative || id !== this.selectedNarrative.id) {
            const allowed = await this.rootStore.routerStore.attemptPush(`/narratives/${id}`);
        
            const narrative = this.narratives.find(n => n.id === id);
            if (narrative && allowed) {
                this.resetInvalid();
                this.selectedNarrative = narrative.clone();
                this.originalNarrative = narrative.clone();
            }
        }
    };

    @action newNarrative = async () => {
        const notDirty = await this.rootStore.routerStore.attemptPush(`/narratives/new`);
        if (notDirty) {
            this.resetInvalid();
            this.selectedNarrative = new Narrative();
            this.originalNarrative = Object.assign(new Narrative(), this.selectedNarrative);
        }
        this.dirty = false;
    };

    @action onChange = (n: Narrative) => {
        this.selectedNarrative = Object.assign(new Narrative(), n);
        this.dirty = true;
    };

    @loadable()
    @action.bound
    async deleteNarrative(id: number) {
        try {
            const idx = this.narratives.findIndex(t => t.id === id);
            if (idx > -1) {
                const allowedToNavigate = await this.rootStore.routerStore.attemptPush(`/narratives/new`);
                
                if (allowedToNavigate) {
                    const confirmDelete = await this.confirm('dialog.confirm.message.delete');
                    if (!confirmDelete) { return };
                    this.narratives[idx].deleted = true;
                    await this.rootStore.api.Narrative.saveNarrative(this.narratives[idx]);
                    this.narratives.splice(idx, 1);
                    // second part of condition for new add entries without id.
                    const index = this.allNarratives.findIndex(t => t.id === id || t.key === this.narratives[idx].key);
                    if (index > -1) {
                        this.allNarratives.splice(index, 1);
                    }
                    // decrement offset because items in backend shifted and next api call would miss next top item
                    this.currentOffset--;
                    this.newNarrative();
                }
            }
            this.rootStore.snackbarStore.triggerSnackbar('app.snackbar.info.deleted');
        } catch (e) {
            logger.error('Narratives, Error deleting narrative', e);
            throw e;
        }
    };

    @loadable()
    @action.bound
    async addNarrative(n: Narrative, isRedirect: boolean) {
        try {
            this.dirty = false;
            const newNarrative = await this.rootStore.api.Narrative.saveNarrative(n);
            this.allNarratives.unshift(newNarrative.clone());
            if (isRedirect) {
                if (this.searchTextInclude(n.key) || this.searchTextInclude(n.replacement)) {
                    const localNarratives = this.narratives.filter(nar => !nar.global);
                    const isAllLocalNarrativesLoaded = localNarratives.length < this.narratives.length;
                    const lastLoadedNarrative = localNarratives.length > 0 ? localNarratives[localNarratives.length - 1] : null;
                    // check if all local narratives loaded OR narrative key is before last local narrative in the list
                    if (isAllLocalNarrativesLoaded || this.caughtEmAll || n.key.localeCompare(lastLoadedNarrative!.key) < 0) {
                        this.narratives.unshift(newNarrative.clone());
                        this.sortNarratives();
                        // increment offset because last item is shifted in backend, to prevent duplicate occurance
                        this.currentOffset++;
                    }
                }
                this.selectedNarrative = newNarrative.clone();
                this.rootStore.routerStore.push(`/narratives/${newNarrative.id}`);
            }
            this.selectedNarrative = new Narrative();
            this.originalNarrative = new Narrative();
            this.rootStore.snackbarStore.triggerSnackbar('app.snackbar.info.saved');
        } catch (e) {
            this.dirty = true;
            throw e;
        }
    }

    @loadable()
    @action.bound
    async saveNarrative(n: Narrative) {
        try {
            this.loading = true;
            n.replacement = n.replacement.trim();
            const newNarrative: Narrative = await this.rootStore.api.Narrative.saveNarrative(n);
            const idx = this.narratives.findIndex(nar => nar.id === n.id);
            if (idx > -1) {
                this.narratives.splice(idx, 1);
            } else {
                this.selectedNarrative = newNarrative.clone();
                this.rootStore.routerStore.push(`/narratives/${newNarrative.id}`);
            }
            this.narratives.unshift(newNarrative.clone());
            this.sortNarratives();
            // second part of condition for new add entries without id.
            const index = this.allNarratives.findIndex(nar => nar.id === n.id || nar.key === this.narratives[idx].key);
            if (index > -1) {
                this.allNarratives.splice(index, 1);
            }
            this.allNarratives.unshift(newNarrative.clone());
            this.selectedNarrative = new Narrative()
            this.originalNarrative = newNarrative.clone();
            this.dirty = false;
            this.rootStore.routerStore.push(`/narratives/new`);
            this.rootStore.snackbarStore.triggerSnackbar('app.snackbar.info.saved');
        } catch (e) {
            throw e;
        } finally {
            this.loading = false;
        }
    };

    searchTextInclude = (s: string) => s.toLowerCase().includes(this.searchText.toLowerCase());

    @action restoreNarrative = () => {
        this.selectedNarrative = Object.assign(new Narrative(), this.originalNarrative);
        this.resetInvalid();
        this.dirty = false;
    };

    @action onSearchChange = (input: string) => {
        this.searchText = input;
        this.selectedNarrative = new Narrative();
        this.debounceOnSearchChange(input);
    };

    @debounce(500, {leading: false})
    async debounceOnSearchChange(input: string) {
        this.reset();
        this.fetchMoreAvailNarratives();
    };

    @action isKeyGlobalDuplicate = (key: string): void => {
        let obj = this.allNarratives.find(n => n.key.toLowerCase() === key.toLowerCase());
        if (obj && obj.global) {
            this.invalid.key = JSON.stringify({
                namespace: 'narrative_field.dialog.create.validation.code.override_global',
                code: obj.key
            });
        }
    };

    @loadable()
    @action.bound
    async searchAvailableNarratives(offset?: number, limit?: number, search?: string): Promise<Narrative[]> {
        return await this.rootStore.api.Narrative.getAllNarratives(offset, limit, search);
    }

    fetchMoreAvailNarratives = async () => {
        if (this.caughtEmAll) {
            return;
        }
        await this.loadMutex.execute(async () => {
            const newElems = await this.searchAvailableNarratives(this.currentOffset, this.limit, this.searchText);
            if (!Array.isArray(newElems)) { return }
            this.currentOffset = this.currentOffset + newElems.length;
            if (newElems.length < this.limit) {
                this.caughtEmAll = true;
            }
            this.narratives = this.narratives.concat(newElems);
            this.sortNarratives();
        });
    }

    fetchAllNarratives = async () => {
        this.fetchingNarratives = true;
        const fetchedNarrative = await this.searchAvailableNarratives();
        this.allNarratives = Array.isArray(fetchedNarrative) ? fetchedNarrative : [];
        this.fetchingNarratives = false;
    }

    reset = () => {
        this.currentOffset = 0;
        this.narratives = [];
        this.caughtEmAll = false;
    }

    sortNarratives = () => {
        // Local Narratives must be always on top
        const localNarratives = (this.narratives.filter(n => !n.global))
            .sort((a, b) => a.key.localeCompare(b.key));
        const globalNarratives = (this.narratives.filter(n => n.global))
            .sort((a, b) => a.key.localeCompare(b.key));

        this.narratives = [...localNarratives, ...globalNarratives];
    }

    @action resetSearchText() {
        this.searchText = '';
    }

    @action resetInvalid() {
        this.invalid = { 'key': false, 'replacement': false };
    }

    @action validateNarrative(n: Narrative) {
        this.invalid.replacement = n.replacement.trim() === '' ? 'narrative_details.validation.text.required' :
                                   n.replacement.trim().length > this.maxNarrativeLength ? 
                                   'narrative_details.validation.text.length' : false;
        const regex = /^[a-zA-Z0-9]+([ -][a-zA-Z0-9]*)*$/;
        this.invalid.key = 
            n.key === '' ? 'narrative_details.validation.code.required' :
                n.key.length < 2 ? 'narrative_details.validation.code.length' :
            (n.key.indexOf(' ') > -1 ? 'narrative_details.validation.code.space' :
            this.fetchingNarratives ? 'narrative_details.validation.code.narratives_not_loaded' :
            (this.isKeyDuplicate(n.key) ? 'narrative_details.validation.code.duplicate' :
            n.key.startsWith('-') ? 'narrative_details.validation.code.starts_with_hyphen' :
            !regex.test(n.key) ? 'narrative_details.validation.code.invalid_characters' :
            false));

        this.invalid.key = this.invalid.key ? JSON.stringify({ namespace: this.invalid.key }) : false;

        return !this.invalid.replacement && !this.invalid.key;
    }

    isKeyDuplicate(key: string): boolean {
        if (this.selectedNarrative.id && this.originalNarrative.key === key) {
            return false;
        }
        return this.allNarratives.findIndex(n =>
            (n.key.toLowerCase() === key.toLowerCase()) && !n.global) > -1;
    }
};