// tslint:disable:no-any

export interface ApiResult<T> {
    status: {
        failed: boolean;
        message: string;
    }
    object: T;
}

export interface ApiResultBulk<T> {
    status: {
        failed: boolean;
        message?: string;
        errors?: {
            [key: string]: []
        };
    }
    object: T;
}

interface PendingCall {
    fn: () => Promise<any>;
    resolve: Function;
    reject: Function;
}
export default class Mutex {
    pendingCalls: PendingCall[] = [];
    executing: boolean = false;
    execute = async (fn: () => Promise<any>) => {
        let prom = new Promise((resolve, reject) => {
            this.pendingCalls.push({
                fn,
                resolve,
                reject
            })
        })
        this.tryExecuteNext();
        return prom;
    }
    tryExecuteNext = async () => {
        if (this.executing) {
            return;
        }
        if (this.pendingCalls.length === 0) {
            return;
        }
        this.executing = true;
        let pending = this.pendingCalls.shift();
        try {
            let result = await pending!.fn();
            pending!.resolve(result);
        } catch (err) {
            pending!.reject(err);
        } finally {
           this.executing = false;
           this.tryExecuteNext();
        }
    }
}

export function  removeUrlParameter(url: string, parameter: string) {
    var urlParts = url.split('?');

    if (urlParts.length >= 2) {
        // Get first part, and remove from array
        var urlBase = urlParts.shift();

        // Join it back up
        var queryString = urlParts.join('?');

        var prefix = encodeURIComponent(parameter) + '=';
        var parts = queryString.split(/[&;]/g);

        // Reverse iteration as may be destructive
        for (var i = parts.length; i-- > 0; ) {
            // Idiom for string.startsWith
            if (parts[i].lastIndexOf(prefix, 0) !== -1) {
                parts.splice(i, 1);
            }
        }

        url = urlBase + '?' + parts.join('&');
    }

    return url;
}