import { action, computed, observable, makeObservable } from 'mobx';
import { DateTime } from 'luxon';
import { RootStore } from 'store/root.store';
import TimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import {
    Client,
    Matter,
    MatterTypeText,
    Features,
    Code,
    ApprovalStatus
} from '../api/types/types';
import TimeEntryManager from './managers/timeentry.manager';
import ImmutableTimeEntry from '../api/immutables/ImmutableTimeEntry';
import { parseCode } from '../util/utils';
import { removeListItem } from 'util/array';

export enum MATTER_TYPE {
    All = 'All',
    BILLABLE = 'Billable',
    NON_BILLABLE = 'Non Billable'
}

interface NarrativePopulatedCode {
    type: string;
    name: string;
    description: string;
}

export default class TimeEntryStore extends TimeEntryManager {
    loc = DateTime.local();
    @observable selectedDates: DateTime[] = [DateTime.utc(this.loc.year, this.loc.month, this.loc.day)];
    @observable fromDate: DateTime = DateTime.utc(this.loc.year, this.loc.month, this.loc.day).minus({ days: 7});
    @observable untilDate: DateTime = DateTime.utc(this.loc.year, this.loc.month, this.loc.day);
    @observable status: string = 'All';
    @observable matterType: string = MATTER_TYPE.All;
    @observable billable: boolean | undefined;
    @observable searchText: string = '';
    @observable client?: Client | undefined | null;
    @observable matter?: Matter | undefined | null;
    @observable referenceTE?: ImmutableTimeEntry | null;
    @observable matterLoading: boolean = false;
    @observable narrativeAutoPopulate: boolean = false;
    @observable showOnlyRejectedEntries: boolean = false;
    narrativePreviousPopulatedCodes: NarrativePopulatedCode[] = [];

    // pagination values
    @observable currentPage: number = 1;
    @observable entriesPerPage: number = 25;
    @observable startIndex: number = 1;
    @observable endIndex: number = 25;

    constructor(root: RootStore) {
        super(root);
        makeObservable(this);
        this.initializeHandler();
    }

    @action.bound
    async setSearchText(s: string) {
        this.searchText = s;
    }

    @action.bound
    async setStatus(s: string) {
        this.status = s;
    }

    @action.bound
    async setMatterType(m: string) {
        this.matterType = m;
    }

    @action.bound
    async setBillable(billable?: boolean) {
        this.billable = billable;
    }

    @action.bound
    async setClient(c: Client | null | undefined) {
        this.client = c;
        if (c === null) {
            this.matter = null;
        }
    }

    @action.bound
    async setMatter(m: Matter | null | undefined) {
        this.matter = m;
        if (m && m!.id !== -1) {
            this.client = {
                id: m!.clientId,
                name: m!.clientName,
                number: m!.clientNumber
            };
        }
    }
    
    @action.bound
    setReferenceTE(r: ImmutableTimeEntry | null | undefined) {
        this.referenceTE = r;
    }

    @action.bound
    toggleShowOnlyRejectedEntries() {
        this.showOnlyRejectedEntries = !this.showOnlyRejectedEntries;
    }

    entryWithinSearch(entry: TimeEntry): boolean {
        let nar = entry.narrative ? entry.narrative.toLowerCase() : '';
        let mname = entry.matterName ? entry.matterName.toLowerCase() : '';
        let mnumber = entry.matterNumber ? entry.matterNumber.toLowerCase() : '';
        let cname = entry.clientName ? entry.clientName.toLowerCase() : '';
        let cnumber = entry.clientNumber ? entry.clientNumber.toLowerCase() : '';
        let office = entry.office ? entry.office.toLowerCase() : '';
        let actCodeTxt = entry.actCode ? entry.actCode.toLowerCase() : '';
        let ffTaskCodeTxt = entry.ffTaskCode ? entry.ffTaskCode.toLowerCase() : '';
        let ffActCodeTxt = entry.ffActCode ? entry.ffActCode.toLowerCase() : '';
        let taskCode = entry.taskCode ? entry.taskCode.toLowerCase() : '';
        let phaseName = entry.phaseName ? entry.phaseName.toLowerCase() : '';
        let duration = (entry.duration / 3600).toFixed(2) + '';
        let reference = entry.reference ? entry.reference.toLowerCase() : '';
        let tkName = entry.employee && entry.timeKeeperName ? entry.timeKeeperName.toLowerCase() : '';

        let searchText = this.searchText.toLowerCase();

        return nar.includes(searchText) ||
            mname.includes(searchText) ||
            mnumber.includes(searchText) ||
            cname.includes(searchText) ||
            cnumber.includes(searchText) ||
            office.includes(searchText) ||
            actCodeTxt.includes(searchText) ||
            ffTaskCodeTxt.includes(searchText) ||
            ffActCodeTxt.includes(searchText) ||
            taskCode.includes(searchText) ||
            phaseName.includes(searchText) ||
            duration.includes(searchText) ||
            reference.includes(searchText) ||
            tkName.includes(searchText);
    }

    @computed get filteredEntries() {
        const isProject = this.rootStore.appStore.isProject;
        let filteredEntries = this.localTimeEntries.filter((te, idx) => {
            if (te.dirty && idx < this.serverTimeEntries.length && te.id === this.serverTimeEntries[idx].id) {
                te = this.serverTimeEntries[idx];
            }
            const sapStatus: string = (te.sapStatus === SapStatus.SUBMITTED || te.sapStatus === SapStatus.QUEUED) 
                ? SapStatus.POSTED : te.sapStatus;
            let flagSearch = this.entryWithinSearch(te);
            let flagStatus = sapStatus === this.status;
            let flagBlank = true;
            let flagMatterType = true;

            if (this.status === 'All') {
                flagStatus = true;
            }

            if (this.matterType !== MATTER_TYPE.All) {
                if (isProject) {
                    flagMatterType = this.billable === te.billable;
                } else {
                    flagMatterType = String(te.matterTypeText).toUpperCase() === MatterTypeText[this.matterType];
                }
            }

            let flagMatter = true;
            if (this.matter) {
                if (this.matter.id === -1) {
                    flagBlank = !te.matter;
                } else {
                    flagMatter = this.matter.number === te.matterNumber;
                }
            }

            let flagClient = true;
            if (this.client) {
                flagClient = this.client.number === te.clientNumber;
            }
            
            let flagReference = true;
            if (this.referenceTE) {
                flagReference = te.reference === this.referenceTE.reference;
            }

            let flagRejected = true;
            if (this.showOnlyRejectedEntries) {
                flagRejected = te.approvalStatus === ApprovalStatus.REJECTED;
            }
            
            return flagSearch && flagStatus && flagMatterType && flagMatter && flagClient && flagBlank && flagReference && flagRejected;
        });
        return filteredEntries;
    }

    @action.bound
    setTimeEntryRange(fromDate: DateTime, untilDate: DateTime) {
        this.setRange(fromDate, untilDate);
    }

    @action.bound
    setTimeEntryDateRange() {
        let f: Features = this.rootStore.appStore.features;
        if (f.EpochConfigTimeEntriesPageDays) {
            let setDays = f.EpochConfigTimeEntriesPageDays;
            let locDateTime = DateTime.utc(this.loc.year, this.loc.month, this.loc.day);
            this.fromDate = setDays ? locDateTime.minus({ days: setDays }) : locDateTime.minus({ days : 7});
            this.untilDate = locDateTime;
        } else {
            this.fromDate = DateTime.utc(this.loc.year, this.loc.month, this.loc.day).minus({ days: 7});
        }
    }
    @computed get timeEntriesMapForPDF(): Map<string, TimeEntry[]> {
        let result = new Map();
        this.filteredEntries.forEach((te: TimeEntry) => {
            let entries = result.get(DateTime.fromISO(te.workDateTime!).toISODate());
            if (entries === undefined) {
                entries = [];
            }
            entries.push(te);
            result.set(DateTime.fromISO(te.workDateTime!).toISODate(), entries);
        });
        return result;
    }

    @computed get timeEntriesMap(): Map<string, TimeEntry[]> {
        let result = new Map();
        this.paginatedTimeEntries.forEach((te: TimeEntry) => {
            let entries = result.get(DateTime.fromISO(te.workDateTime!).toISODate());
            if (entries === undefined) {
                entries = [];
            }
            entries.push(te);
            // tslint:disable-next-line:no-any
            entries.sort((a: any, b: any) => {
                return  DateTime.fromISO(a.lastModified) > DateTime.fromISO(b.lastModified) ? -1 : 1;
            }).map((ent: TimeEntry) => {
                if (this.validationState.get(ent.id!) || this.durVstate.get(ent.id!)) {
                    entries = removeListItem(entries, ent.id!);
                    entries.unshift(ent);
                }
            });
            result.set(DateTime.fromISO(te.workDateTime!).toISODate(), entries);
        });
        return result;
    }
    
    @computed get groupEntriesByMatter(): Map<string, TimeEntry[]> {
        let result = new Map();
        this.filteredEntries.forEach((te: TimeEntry) => {
            if (te.matterId) {
                let entries = result.get(parseCode(te.matterNumber, te.matterName));
                if (entries === undefined) {
                    entries = [];
                }
                entries.push(te);
                result.set(parseCode(te.matterNumber, te.matterName), entries);
            }
        })
        result.forEach((te) => {
            te.sort((a: TimeEntry, b: TimeEntry) => Date.parse(a.workDateTime) - Date.parse(b.workDateTime));
        })
        return result;
    }

    @computed get groupEntriesByClient(): Map<string, TimeEntry[]> {
        let result = new Map();
        this.filteredEntries.forEach((te: TimeEntry) => {
            if (te.clientId) {
                let entries = result.get(parseCode(te.clientNumber, te.clientName));
                if (entries === undefined) {
                    entries = [];
                }
                entries.push(te);
                result.set(parseCode(te.clientNumber, te.clientName), entries);
            }
        })
        result.forEach((te) => {
            te.sort((a: TimeEntry, b: TimeEntry) => Date.parse(a.workDateTime) - Date.parse(b.workDateTime));
        })
        return result;
    }

    @computed get groupEntriesByTimeKeeper(): Map<string, TimeEntry[]> {
        let result = new Map();
        this.filteredEntries.forEach((te: TimeEntry) => {
            if (te.timeKeeperId) {
                let entries = result.get(parseCode(te.timeKeeperId, te.timeKeeperName));
                if (entries === undefined) {
                    entries = [];
                }
                entries.push(te);
                result.set(parseCode(te.timeKeeperId, te.timeKeeperName), entries);
            }
        })
        result.forEach((te) => {
            te.sort((a: TimeEntry, b: TimeEntry) => Date.parse(a.workDateTime) - Date.parse(b.workDateTime));
        })
        return result;
    }

    @action.bound
    async setRange(start: DateTime, end: DateTime) {
        this.fromDate = start;
        this.untilDate = end;
    };

    @action.bound
    setEntriesPerPage(n: number) {
        this.entriesPerPage = n;
    }

    @action.bound
    setPageNum(n: number) {
        this.currentPage = n;
        this.startIndex = this.currentPage === 1 ? 1 : (this.currentPage - 1) * this.entriesPerPage + 1;
        this.endIndex = this.currentPage * this.entriesPerPage;
    }

    @action.bound
    async getEntries() {
        await this.loadEntries(this.fromDate, this.untilDate);
    }
    
    @computed get noEntries(): boolean {
        return this.paginatedTimeEntries.length === 0;
    }

    /**
     * returns true if all entries are selected from paginatedTimeEntries
     */
    @computed get allEntriesSelected(): boolean {
        if (this.paginatedTimeEntries.length !== this.selectedEntryIds.length) {
            return false;
        }
        let selectedEntryIds = this.selectedEntryIds.slice().sort();
        let allEntryIds = this.paginatedTimeEntries.map((fe => fe.id!)).sort();
        return selectedEntryIds.every((val, index) => val === allEntryIds[index]);
    }

    @action.bound
    toggleSelectAllFlag() {
        if (!this.allEntriesSelected) {
            this.selectedEntryIds = this.paginatedTimeEntries.map(fe => fe.id!);
        } else {
            this.selectedEntryIds = [];
        }
    }

    @computed get paginatedTimeEntries() {
        // tslint:disable-next-line:no-any
        let sortedEntries = this.filteredEntries.sort((s1: any, s2: any) => {
            if (s1.workDateTime === s2.workDateTime) {
                return s2.lastModified > s1.lastModified ? 1 : 
                        s1.lastModified > s2.lastModified ? -1 : 0;
            } else {
                return s2.workDateTime > s1.workDateTime ? -1 : 
                        s1.workDateTime > s2.workDateTime ? 1 : 0;
            }
        })
        return sortedEntries.slice(this.startIndex - 1, this.endIndex);
    }

    @action.bound
    resetTimeEntryStore() {
        this.client = undefined;
        this.matter = undefined;
        this.setTimeEntryDateRange()
        this.status = 'All';
        this.searchText = '';
        this.matterType = MATTER_TYPE.All;
        this.referenceTE = null;
        this.localTimeEntries = [];
        // reset Pagination values
        this.startIndex = 1;
        this.endIndex = 25;
        this.setEntriesPerPage(25);
        this.setPageNum(1);
    }

    @action.bound
    setFieldLoaderFn(val: boolean) {
        this.matterLoading = val;
    }

    @action.bound
    setNarrativeAutoPopulate(val: boolean) {
        this.narrativeAutoPopulate = val;
    }

    @action.bound
    resetNarrativePreviousPopulatedCodes() {
        this.setNarrativeAutoPopulate(false);
        this.narrativePreviousPopulatedCodes = [];
    }

    @action.bound
    setNarrativeFromCodes(entry: TimeEntry, code: Code) {
        if (this.narrativeAutoPopulate) {
            if (!this.narrativePreviousPopulatedCodes.some(e => (e.type === code.type && e.name === code.name))) {
                this.narrativePreviousPopulatedCodes.push({
                    type: code.type,
                    name: code.name,
                    description: code.description
                });
                return entry.setNarrative(`${code.description} ${entry.narrative ? entry.narrative : ''}`);
            }
        }
        return entry;
    }
}