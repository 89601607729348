import { DateTime } from 'luxon';
import { countryTimeZone } from '../App';

export const FIRST_EDITABLE_DATE = DateTime.utc().startOf('month').minus({months: 2});

export const LAST_EDITABLE_DATE = DateTime.utc().endOf('month');

export function withinEditableRange(day: DateTime): boolean {
    return (day >= FIRST_EDITABLE_DATE && day <= LAST_EDITABLE_DATE);
}

export function parseDuration(duration: string): number {
    const dur = duration.split('-');
    if (dur.length > 1 && dur.every(d => d === dur[0])) {
        throw new Error('Bad duration text');
    }
    if (dur.length === 1) {
        return getTimeFromDurationString(duration);
    } else if (dur.length === 2) {
        const start = getMinutesFromTime(dur[0]);
        const end = getMinutesFromTime(dur[1]);
        if (start > end) {
            throw new Error('Bad duration text')
        }
        return end - start;
    }
    throw new Error('Bad duration text');
}
function getTimeFromDurationString(duration: string): number {
    const calcDuration = Number(duration);
    if (!isNaN(calcDuration)) {
        const result = (calcDuration * 60).toFixed(2);
        return Number(result);
    }
    const hoursMinutesRegEx = /\d{1,2}h\d{1,2}m/gi;
    const decHRegEx = /\d{1,20}.\d{1,20}h/gi;
    const hoursHRegEx = /\d{1,20}h/gi;
    const minutesRegEx = /\d{1,20}m/gi;
    if (hoursMinutesRegEx.test(duration)) {
        const durs = duration.substring(0, duration.length - 1).split('h');
        return (Number(durs[0]) * 60) + Number(durs[1]);
    }
    if (minutesRegEx.test(duration)) {
        const mins = Number(duration.substring(0, duration.length - 1));
        if (isNaN(mins)) { throw new Error('Bad duration text'); } // should never happen
        return mins;
    }
    if (hoursHRegEx.test(duration)) {
        const hrs = Number(duration.substring(0, duration.length - 1));
        if (isNaN(hrs)) { throw new Error('Bad duration text'); } // should never happen
        return hrs * 60;
    }
    if (decHRegEx.test(duration)) {
        const dec = Number(duration.substring(0, duration.length - 1));
        if (isNaN(dec)) { throw new Error('Bad duration text'); } // should never happen
        return dec * 60;
    }

    throw new Error('Bad duration text');
}

function getMinutesFromTime(range: string): number {
    range = range.replace(/ /g, '');
    const hourAm = range.match(/\d{1,2}(am|a)/gi);
    const hourPm = range.match(/\d{1,2}(pm|p)/gi);
    let matchedRange = range.match(/\d{1,2}/gi);
    if (matchedRange) {
        if (hourAm) {
            if (hourAm.length === 1) {
                if (hourAm[0] === range) {
                    if (Number(matchedRange[0]) === 12) {
                        return 0;
                    }
                    return Number(matchedRange[0]) * 60;
                }
            }
        }
        if (hourPm) {
            if (hourPm.length === 1) {
                if (hourPm[0] === range) {
                    if (Number(matchedRange[0]) === 12) {
                        return 720;
                    }
                    return (Number(matchedRange[0]) + 12) * 60;
                }
            }
        }
    }
    const fullTimeAM = range.match(/\d{1,2}:\d{2}(am|a)/gi);
    if (fullTimeAM) {
        if (fullTimeAM.length === 1) {
            if (fullTimeAM[0] === range) {
                const time = range.match(/\d{1,2}:\d{2}/gi);
                if (time) {
                    const ts = time[0].split(':');
                    if (Number(ts[0]) === 12) {
                        ts[0] = '0';
                    }
                    return (Number(ts[0]) * 60) + Number(ts[1]);
                }
            }
        }
    }
    const fullTimePM = range.match(/\d{1,2}:\d{2}(pm|p)/gi);
    if (fullTimePM) {
        if (fullTimePM.length === 1) {
            if (fullTimePM[0] === range) {
                const time = range.match(/\d{1,2}:\d{2}/gi);
                if (time) {
                    const ts = time[0].split(':');
                    if (Number(ts[0]) === 12) {
                        return (Number(ts[0]) * 60) + Number(ts[1]);
                    }
                    return (Number(ts[0]) * 60) + Number(ts[1]) + (60 * 12);
                }
            }
        }
    }
    throw new Error('Bad duration text');
}

export function getDateFormat(): string {
    const countrycode = countryTimeZone.getTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const format = (countrycode.country || 'US').toUpperCase() === 'US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
    return format;
}

export function getUserLocale(): string {
    const countrycode = countryTimeZone.getTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const locale = (countrycode.country || 'US').toUpperCase() === 'US' ? 'en-US' : 'en-GB';
    return locale;
}

export function isoDate(date: DateTime): string {
    return  DateTime.local(
        date.year,
        date.month,
        date.day
    ).toFormat('MM/dd/yyyy')
}
