import TimeEntryAPI, { AssociateApiResult } from 'api/interfaces/TimeEntryAPI';
import BaseWebImplementation from './Base.impl';
import { DateTime } from 'luxon';
import ImmutableTimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import { ApiResult, ApiResultBulk } from 'api/util';
import { Platform } from '../../../util/Platform';
import { BulkUploadEntry, Role, TimeCastSegment, TimerChunk } from '../../types/types';
import rootStore from 'store/root.store';
import { FileSaver } from 'util/ExportToExcel';

export default class TimeEntryImpl extends BaseWebImplementation implements TimeEntryAPI {
    handlers: (((entries: ImmutableTimeEntry[]) => void) | null)[] = [];

    async getEntries(fromDate: DateTime, toDate: DateTime, tkId: number) {
        const { data } = await this.http.get(
            '/timeEntries?' +
            `fromDate=${fromDate.toISO()}` +
            `&toDate=${toDate.toISO()}` +
            `&tkId=${tkId}` +
            `&excludeCreatedOn=true`
        );

        return data.map((d: object) => Object.assign(new ImmutableTimeEntry(), d));
    }

    async getEntry(id: number) {
        const { data } = await this.http.get(`/timeEntries/${id}`);
        return Object.assign(new ImmutableTimeEntry(), data);
    }

    updateEntries = async (entries: ImmutableTimeEntry[], role?: Role, approverTkId?: number): Promise<ApiResult<ImmutableTimeEntry>[]> => {
        const sessionId = localStorage.getItem('sessionId');
        let entryData = entries.map((t, index) => {
            // if collaborateTks exist and not empty string use it otherwise use collaborateInfo
            const collaborateTE = t.collaborateTks && t.collaborateTks.length > 0 ? 'collaborateTks' : 'collaborateInfo';
            let te = {
                id: t.id,
                office: t.office,
                officeName: t.officeName,
                matterId: t.matterId,
                timeKeeperId: t.timeKeeperId,
                phaseId: t.phaseId,
                taskCodeId: t.taskCodeId,
                actCodeId: t.actCodeId,
                ffTaskCodeId: t.ffTaskCodeId,
                ffActCodeId: t.ffActCodeId,
                duration: t.duration,
                actualDuration: t.actualDuration,
                reference: t.reference ? t.reference.trim() : null,
                workLocation: t.workLocation,
                narrative: t.narrative,
                actionCodeId: t.actionCodeId,
                sapStatus: t.sapStatus,
                approvalStatus: t.approvalStatus,
                workDateTime: t.workDateTime,
                deleted: t.deleted,
                billingLang: t.billingLang,
                billingLangText: t.billingLangText,
                billable: t.billable,
                createdOn: t.createdOn,
                timeEntryType: t.timeEntryType,
                [collaborateTE]: t[collaborateTE],
                workLocaleId: t.workLocaleId,
                guid: t.guid
            };
            if (Platform.isWeb() && !te.id) {
                // let randomNumber = Math.floor((Math.random() * 100) + 1)
                te.workLocation = `WEB_${new Date().getTime()}${index}_${sessionId}`;
                te.createdOn = (new Date()).toISOString();
            }
            return te;
        });
        let savedEntries: ImmutableTimeEntry[] = [];
        let resp: ApiResult<ImmutableTimeEntry>[] = [];

        try {
            const queryParameters = role ? `?role=${role}&approverTkId=${approverTkId}` : '';
            const { data } = await this.http.put(`/timeEntries${queryParameters}`, entryData);
            resp = data;

            // tslint:disable-next-line:no-any
            savedEntries = data.filter((r: any) => !r.status.failed).map((r: any) => {
                rootStore.timeEntryStore.removeFromCollaborateEntriesIds(r.object.id);
                return Object.assign(new ImmutableTimeEntry(), r.object)
            });
        } catch (e) {
            // logger.error('Time Entries, Update Entries failed.\n', e)
            savedEntries = entries;
            resp = JSON.parse(entries.toString());
            rootStore.snackbarStore.triggerSnackbar(e);
        } finally {
            if (savedEntries.length > 0) {
                let syntheticSync = {
                    templates: [],
                    glossaries: [],
                    timers: [],
                    timeEntries: savedEntries
                }
                this.root.Session.tabexClient.emit('sync', syntheticSync, true);
                // this.recieve(savedEntries);
            }
            return resp;
        }
    }
    unpostEntries = async (ids: number[]) => {
        const { data } = await this.http.post('/timeEntries/unpost', ids);
        if (!data[0].status.failed) {
            const entry: ImmutableTimeEntry = rootStore.homeStore.localTimeEntries.find(ent =>
                ent.id === data[0].object
            )!;
            entry.sapStatus = SapStatus.UNSUBMITTED;
            let syntheticSync = {
                templates: [],
                glossaries: [],
                timers: [],
                timeEntries: [entry]
            };
            this.root.Session.tabexClient.emit('sync', syntheticSync, true);
        }
        return data;
    }
    registerReciever = (handler: (entries: ImmutableTimeEntry[]) => void) => {
        this.handlers.push(handler);
        const theIndex = this.handlers.length - 1;
        return () => {
            this.handlers[theIndex] = null;
        }
    }
    getTotalForDateExclusive = async (date: string, excludeIds: number[]): Promise<number> => {
        let te: ImmutableTimeEntry[] = await this.getEntries(
            DateTime.fromISO(date),
            DateTime.fromISO(date),
            this.root.Session.currentTimeKeeper!
        );
        return te.reduce((prev, cur) => {
            if (cur.id && excludeIds.includes(cur.id)) {
                return prev;
            }
            return prev + cur.duration;
        }, 0);
    }
    recieve = (timeEntries: ImmutableTimeEntry[]) => {
        this.handlers.filter(h => h !== null).forEach(h => h!(timeEntries));
    }
    getTKHours = async (date: DateTime) => {
        const tkId = this.root.Session.currentTimeKeeper!;
        const { data } = await this.http.get(`tkhours/tk/${tkId}?date=${date.toISODate()}`);
        return data[0];
    }
    getTimeEntriesCount = async (fromDate: DateTime, toDate: DateTime) => {
        const { data } = await this.http.get(
            '/timeEntries/dayCounts?' +
            `fromDate=${fromDate.toISODate()}` +
            `&toDate=${toDate.toISODate()}`
        );
        return data;
    }
    associateSegmentsToEntry = async (entry: ImmutableTimeEntry, chunks?: TimerChunk[], tcSegs?: TimeCastSegment[]) => {
        try {
            const result = await this.updateEntries([entry]);
            const apiResult: AssociateApiResult = {
                TimeEntryApi: result[0]
            };
            const timeEntry = result[0].object;
            if (chunks && chunks.length > 0) {
                chunks.forEach(chunk => {
                    chunk.timeEntryId = timeEntry.id;
                    if (timeEntry.sapStatus !== SapStatus.UNSUBMITTED) {
                        chunk.submitted = true;
                    }
                    return chunk;
                });
                apiResult.TimerChunkApis = await this.root.Timer.updateChunks(chunks);
            }
            if (tcSegs && tcSegs.length > 0) {
                tcSegs.forEach(seg => {
                    seg.associatedTimeEntry = timeEntry.id;
                    return seg;
                });
                apiResult.TimeCastApis = await this.root.TimeCast.saveSegments(tcSegs);
            }
            return apiResult;
        } catch (e) {
            // logger.error('Time Entries, Associate Segments To Entry failed.\n', e)
            const teProm: ApiResult<ImmutableTimeEntry> = {
                status: {
                    failed: true,
                    message: 'Failed save'
                },
                object: entry
            };
            let chunkProm: ApiResult<TimerChunk>[] = [];
            if (chunks && chunks.length > 0) {
                chunks.map((chnk) => {
                    chunkProm.push({
                        status: {
                            failed: true,
                            message: 'Failed save'
                        },
                        object: chnk
                    });
                });
            }
            let tcApis: ApiResult<TimeCastSegment>[] = [];
            if (tcSegs && tcSegs.length > 0) {
                tcSegs.map((seg) => {
                    tcApis.push({
                        status: {
                            failed: true,
                            message: 'Failed save'
                        },
                        object: seg
                    });
                });
            }
            const apiResult: AssociateApiResult = {
                TimeEntryApi: teProm,
                TimerChunkApis: chunkProm,
                TimeCastApis: tcApis
            };
            return apiResult;
        }
    }
    searchWorkLocales = async (searchText: string) => {
        const { data } = await this.http.get(`workLocale?search=${encodeURIComponent(searchText)}`);
        return data;
    }
    
    uploadTimeEntries = async (entries: BulkUploadEntry[]): Promise<ApiResultBulk<BulkUploadEntry>[]> => {
        try {
            const { data } = await this.http.put(
                `timeEntries/bulk`,
                entries
            );
            return data;
        } catch (e) {
            throw e;
        }
    }

    async getAuditLog(id: number) {
        const { data } = await this.http.get(`/timeEntries/${id}/auditLog`);
        return data;
    }

    downloadBulkEntriesTemplate() {
        this.http.get(
            '/timeEntries/uploadTemplate', { responseType: 'blob' }
        ).then(async (response) => {
            rootStore.snackbarStore.closeSnackbar();
            await FileSaver.saveAs(response.data, 'TimeEntryUpload.xlsx');
            rootStore.snackbarStore.triggerSnackbar('header.action.bulk_download.success', { ns: 'timeentries' });
        }).catch((error) => {
            rootStore.snackbarStore.closeSnackbar();
            rootStore.snackbarStore.triggerSnackbar(error, undefined, true);
        });
    }
}