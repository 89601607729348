import BaseWebImplementation from './Base.impl';
import TimerAPI from 'api/interfaces/TimerAPI';
import ImmutableTimer from 'api/immutables/ImmutableTimer';
import { ApiResult } from 'api/util';
import { Client, Matter, TimerChunk } from '../../types/types';
import { DateTime } from 'luxon';
import { Platform } from '../../../util/Platform';

export default class TimerImpl extends BaseWebImplementation implements TimerAPI {
    handlers: (((entries: ImmutableTimer[]) => void) | null)[] = [];
    syncUpdateHomeChunks = (updated: TimerChunk[]) => {
        // do nothing
    };

    get = async (id: number) => {
        const {data} = await this.http.get(`/timers/${id}`);
        return Object.assign(new ImmutableTimer(), data);
    }
    getAll = async () => {
        const {data} = await this.http.get(
            `/timers?tkId=${this.root.Session.currentTimeKeeper!}`
        );
        return data.map((d: object) => Object.assign(new ImmutableTimer(), d));
    }
    getTimersFromIds = async (ids: number[]) => {
        let csValues = ids.join(',');
        const {data} = await this.http.get(
            `/timers?tkId=${this.root.Session.currentTimeKeeper!}&timerIds=${csValues}`
        );
        return data.map((d: object) => Object.assign(new ImmutableTimer(), d));
    }
    updateHomeChunksListener = (handler: (updated: TimerChunk[]) => void) => {
        this.syncUpdateHomeChunks = handler;
    }
    updateChunks = async (timerChunks: TimerChunk[], timers?: ImmutableTimer[]): Promise<ApiResult<TimerChunk>[]> => {
        let serverChunks = timerChunks.map(tc => ({
            id: tc.id,
            startTime: tc.startTime,
            endTime: tc.endTime,
            description: tc.description || '',
            timerId: tc.timerId,
            timeEntryId: tc.timeEntryId,
            deleted: tc.deleted
        }));
        const {data} = await this.http.put('/timers/chunks', serverChunks);
        return data;
    }
    /* This method should be used only when a TimeEntry is posted on already associated segments, 
        cannot be used on new associations. It causes asynchronous issues.
     */
    updateTimerDurationFromTimeEntry = async(ids: number[]) => {
        let chunks = await this.getChunksByTimeEntryId(ids);
        if (chunks && chunks.length > 0 ) {
            let timerIds: number[] = [];
            chunks.forEach((c) => timerIds.push(c.timerId));
            timerIds = Array.from(new Set(timerIds.map(id => id)));
            let timers = await this.getTimersFromIds(timerIds);
            this.recieve(timers);
        }
    }
    updateTimers = async (timers: ImmutableTimer[]): Promise<ApiResult<ImmutableTimer>[]> => {
        const sessionId = localStorage.getItem('sessionId');
        let serverEntries = timers.map((t, index) => {
            const newT = t.toWriteable();
            if (Platform.isWeb() && !newT.id) {
                newT.localUid = `WEB_${new Date().getTime()}${index}_${sessionId}`;
            }
            return newT;
        });
        let resp: ApiResult<ImmutableTimer>[] = [];
        try {
            const { data } = await this.http.put('/timers', serverEntries);
            // tslint:disable-next-line:no-any
            resp = data.map((obj: any) => {
                let reqTimer = timers.find(t => t.id === obj.object.id);
                let finalObj = reqTimer ? {
                    ...obj.object,
                    pendingDuration: reqTimer.pendingDuration,
                    convertedDuration: reqTimer.convertedDuration,
                    totalDuration: reqTimer.totalDuration,
                } : { ...obj.object };
                return { ...obj, object: finalObj };
            });
        } catch (e) {
            throw e;
        } finally {
            return resp;
        }
    }
    updateTimerSync = async (timer: ImmutableTimer) => {
        const { data } = await this.http.put('/timers', [timer]);
        // tslint:disable-next-line:no-any
        let timerData = data.map((d: any) => {
            const immutableTimer = Object.assign(new ImmutableTimer(), d.object);
            immutableTimer.pendingDuration = timer.pendingDuration;
            immutableTimer.convertedDuration = timer.convertedDuration;
            immutableTimer.totalDuration = timer.totalDuration;

            return immutableTimer;
        });
        this.recieve(timerData);
    }
    getChunks = async (id: number) => {
        const {data} = await this.http.get(`/timers/${id}/chunks`);
        return data;
    }
    start = async (timer: ImmutableTimer, isPlaying: boolean) => {
        let toStart = timer.clone();
        let timerArray = [];
        let stoppedTimer = isPlaying ? await this.stop(false) : null;
        if (stoppedTimer) {
            timerArray.push(stoppedTimer);
        }
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const serverEntry = {
            id: toStart.id,
            timeKeeperId: toStart.timeKeeperId,
            templateId: toStart.templateId,
            matterId: toStart.matterId,
            active: true,
            startedOn: (new Date()).toISOString(),
            startedTimezone: timeZone,
            notes: '',
            name: toStart.name,
            favorite: toStart.favorite,
            lastActive: DateTime.utc().toISO()
        };
        const {data} = await this.http.put('/timers', [serverEntry]);
        timerArray.push(Object.assign(toStart, serverEntry));
        let syntheticSync = {
            templates: [],
            timeEntries: [],
            timers: timerArray,
            glossaries: []
        };
        this.root.Session.tabexClient.emit('sync', syntheticSync);
        return timerArray;
    }
    stop = async (emit = true) => {
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const {data} = await this.http.put(
            `/timers/stopIfRunning?tkId=${this.root.Session.currentTimeKeeper!}&timeZone=${timeZone}`
        );

        let stopped = Object.assign(new ImmutableTimer(), data);
        stopped.lastActive = DateTime.utc().toISO();
        this.syncUpdateHomeChunks(stopped.chunks || []);
        if (emit) {
            let syntheticSync = {
                templates: [],
                timeEntries: [],
                glossaries: [],
                timers: [stopped]
            };
            this.root.Session.tabexClient.emit('sync', syntheticSync);
        }
        return stopped;
    }
    registerReciever = (handler: (timers: ImmutableTimer[]) => void) => {
        this.handlers.push(handler);
        const theIndex = this.handlers.length - 1;
        return () => {
            this.handlers[theIndex] = null;
        };
    }
    recieve = (timers: ImmutableTimer[]) => {
        this.handlers.filter(h => h !== null).forEach(h => h!(timers));
    }
    getChunksByTimeEntryId = async (ids: number[]): Promise<TimerChunk[]> => {
        let csValues = ids.join(',');
        const {data} = await this.http.get(`/timeEntries/chunks?ids=${csValues}`);
        return data;
    }
    filterTimersByClient = async (clientId: number): Promise<ImmutableTimer[]> => {
        const {data} = await this.http.get(
            `/timers?tkId=${this.root.Session.currentTimeKeeper!}&clientId=${clientId}`
        );
        return data.map((d: object) => Object.assign(new ImmutableTimer(), d));
    }
    filterTimersByMatter = async (matterId: number): Promise<ImmutableTimer[]> => {
        const {data} = await this.http.get(
            `/timers?tkId=${this.root.Session.currentTimeKeeper!}&matterId=${matterId}`
        );
        return data.map((d: object) => Object.assign(new ImmutableTimer(), d));
    }
    getDistinctMattersFromTimers = async (search: string, clientId?: number): Promise<Matter[]> => {
        const { data } = await this.http.get(
            `/matters/timers${search || clientId ? `?` : ``}` +
            `${search ? `search=${encodeURIComponent(search)}` : ``}` +
            `${search && clientId ? `&` : ``}` +
            `${clientId ? `clientId=${clientId}` : ``}`);
        return data;
    }
    getDistinctClientsFromTimers = async (search: string): Promise<Client[]> => {
        const { data } = await this.http.get(
            `/clients/timers${search ? `?search=${encodeURIComponent(search)}` : ``}`
        );
        return data;
    }
    getTimersForDay = async (date: DateTime) => {
        const timeZoneOffset = new Date().getTimezoneOffset(); // minutes
        const from = date.startOf('day').plus({minutes: timeZoneOffset}).toISO();
        const to = date.endOf('day').plus({minutes: timeZoneOffset}).toISO();
        const tk = this.root.Session.currentTimeKeeper || 0;
        
        const { data } = await this.http.get(`/timers?tkId=${tk}&fromDate=${from}&toDate=${to}`);
        let timersForDay: ImmutableTimer[] = data.map((t: object) => Object.assign(new ImmutableTimer(), t));
        let filteredTimers: ImmutableTimer[] = [];
        timersForDay.forEach((timer) => {
            let chunks = timer.chunks;
            // Filter chunks for the date provided
            chunks = chunks.filter((chunk) => {
                const d = new Date(chunk.startTime);
                const startTime = DateTime.local(
                    d.getFullYear(),
                    d.getMonth() + 1,
                    d.getDate(),
                    d.getHours(),
                    d.getMinutes(),
                    d.getSeconds(),
                    d.getMilliseconds()
                );
                return (startTime >= date.startOf('day') && startTime < date.endOf('day') && !chunk.deleted);
            });
            timer = { ...timer,
                chunks: chunks
            };
            if (chunks.length > 0 && !timer.deleted) {
                filteredTimers.push(timer);
            }
        });
        return filteredTimers;
    }
}
