import * as React from 'react';
import { Action, Expander, FulcrumEditor, Spellcheck } from '@fulcrumgt/react-fulcrum-editor';
import { FulcrumEditorProps, FulcrumEditorTheme } from '@fulcrumgt/react-fulcrum-editor/types/FulcrumEditor';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import NarrativeStore from 'store/narrative.store';
import NarrativeCodesDialog from 'components/NarrativeCodesDialog';
import NarrativeCodesDialogStore from 'store/narrativeCodes.dialog.store';
import { CustomDictionary, Narrative } from '../../api/types/types';
import { theme } from 'common/theme';
import CreateNarrativeCodeDialogStore from 'store/createNarrativeCodeDialog.store';
import CustomDictionaryStore from 'store/customDictionary.store';
import { PluginName } from '@fulcrumgt/react-fulcrum-editor/types/Plugins/Plugin';
import { withTranslation } from 'react-i18next';

interface NarrativeState {
    dictionaryKey?: string;
}

interface Props extends FulcrumEditorProps {
    disableNarrativeContextMenuActions?: boolean,
    dictionaryKey?: string,
    changeLanguage?: (key: string) => void,
    // stores
    narrativeStore?: NarrativeStore,
    codesStore?: NarrativeCodesDialogStore,
    createNarrativeCodeDialogStore?: CreateNarrativeCodeDialogStore,
    customDictionaryStore?: CustomDictionaryStore,
    highlight?: () => void;
    // tslint:disable-next-line:no-any
    t?: any
}

const FULCRUM_EDITOR_THEME: FulcrumEditorTheme = {
    primaryColor: theme.primary.dark,
    errorColor: theme.error
};

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        createNarrativeCodeDialogStore: rootStore.createNarrativeCodeDialogStore,
        codesStore: rootStore.narrativeCodesDialogStore,
        narrativeStore: rootStore.narrativeStore,
        customDictionaryStore: rootStore.customDictionaryStore
    };
})
@observer
class NarrativeField extends React.Component<Props, NarrativeState> {
    static defaultProps = {
        disableNarrativeContextMenuActions: false,
        
        // default fulcrum-editor props:
        contextMenuStyle: { zIndex: 1337 },
        theme: FULCRUM_EDITOR_THEME,
        debouncedUpdate: true,
        maxLength: 255,
        style: {
            maxHeight: '100px',
            overflow: 'auto'
        },
        showCharCount: true
    }
    constructor(props: Props) {
        super(props);
        this.state = {
            dictionaryKey: this.props.dictionaryKey
        }
    }
    
    componentWillReceiveProps(nextProps: Readonly<Props>): void {
        this.setState({ dictionaryKey: nextProps.dictionaryKey });
    }

    changeDictionary = (key: string) => {
        if (this.props.changeLanguage) {
            this.props.changeLanguage(key);
        }
        this.setState({ dictionaryKey: key });
    };
    
    componentDidUpdate() {
        this.props.customDictionaryStore!.ignoreCustomWords();
        if (this.props.highlight) {
            this.props.highlight();
        }
    }

    render() {
        const { t, disabled } = this.props;
        return (
            <>
                <FulcrumEditor
                    {...this.props}
                >
                    <Spellcheck 
                        maxSuggestions={5}
                        dictionary={this.state.dictionaryKey}
                        onDictionaryChange={(key) => this.changeDictionary(key)}
                    />
                    <Action
                        disabled={this.props.disableNarrativeContextMenuActions}
                        act={async (editorActions) => {
                            let narratives = this.props.codesStore!.narratives;
                            const response = await this.props.codesStore!.open(
                                    narratives ? narratives : []
                                )
                                .catch(() => null);
                
                            if (response && response.replacement) {
                                const narrative = response as Narrative;
                
                                editorActions.replaceSelection(narrative.replacement);
                                editorActions.focus();
                            }
                            editorActions.focus();
                        }}
                    >
                        {t('narrative_field.action.narratives')}
                    </Action>
                    <Action
                        disableWhen={context => !!context.selection && context.selection.length > 0}
                        disabled={this.props.disableNarrativeContextMenuActions}
                        act={async (editorActions) => {
                            const store = this.props.createNarrativeCodeDialogStore!;

                            store.setNarrativeKey('');
                            store.setNarrativeReplacement(editorActions.context.selection);

                            await store.open().catch(() => null);
                        }}
                    >
                        {t('narrative_field.action.create')}
                    </Action>
                    <Action
                        act={async (editorActions) => {
                            const store = this.props.customDictionaryStore!;
                            const dictObj = new CustomDictionary(editorActions.context.selection.trim());
                            store.addNewWord(dictObj).then(() => {
                                editorActions.updatePlugins(true, 'SPELLCHECK' as PluginName);
                            })
                        }}
                        disableWhen={context => {
                            const store = this.props.customDictionaryStore!;
                            return (!!context.selection &&
                                context.selection.trim().split(' ').length === 1 &&
                                !store.isWordDuplicate(context.selection.trim()))
                                && context.selection.trim().length !== 0;
                        }}
                    >
                        {t('narrative_field.action.add_to_dictionary')}
                    </Action>
                    <Expander prefix={'\\'} provider={this.expand}/>
                </FulcrumEditor>
                <NarrativeCodesDialog/>
            </>
        );
    }
    
    private expand = async (word: string): Promise<string | null> => {
        const narrativeStore = this.props.narrativeStore!;

        if (narrativeStore.allNarratives.length <= 0) {
            await narrativeStore.fetchAllNarratives();
        }
        // here, if we create local narrative for global  we will get  both global and local narrative 
        const possibleReplacement = narrativeStore.allNarratives.filter(narrative => {
            return !narrative.deleted && narrative.key.toLowerCase() === word.toLowerCase();
        });

        // global and local narrative stored in separate variables 
        let locNarrResults = possibleReplacement.filter((entry) => entry.global === false);
        let gloNarrResults = possibleReplacement.filter((entry) => entry.global === true);

        // fetching global and local narratives
        if (possibleReplacement.length > 0) {
            return locNarrResults.length === 1 ? locNarrResults[0].replacement : gloNarrResults[0].replacement;
        }

        return null;
    };
}

export default withTranslation(['narratives'])(NarrativeField);