import * as React from 'react';
import { useContext } from 'react';
import { IconButton } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { RootStoreContext } from '../../App';

function BulkTemplateDownloadButton() {
    const rootStore = useContext(RootStoreContext);
    const { t } = useTranslation('timeentries');

    return (
        <IconButton
            key={'download'}
            title={t('header.action.bulk_download.tooltip')}
            onClick={rootStore.bulkUploadStore.downloadBulkTemplate}
        >
            <CloudDownload />
        </IconButton>
    );
}

export default BulkTemplateDownloadButton;