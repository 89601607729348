import * as React from 'react';
import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import {
    AppBar,
    Button,
    Checkbox,
    Dialog,
    FormControlLabel,
    Toolbar,
    Tooltip
} from '@material-ui/core';
import * as Styled from './styled';
import { LoadingDiv } from 'containers/Home/Home';
import { RootStoreContext } from '../../App';
import BulkEntry from './BulkEntry';
import { FIELDS } from 'api/types/types';
import Pagination from 'containers/Pagination/Pagination';

interface Fields {
    [key: string]: {
        labelKey: string;
        minWidth?: string;
        width?: string;
        maxChar?: number;
    }
}

export const FIELDS_PROPS: Fields = {
    [FIELDS.ROW]: {labelKey: 'row_number', minWidth: '40px', width: '40px'},
    [FIELDS.WORK_DATE]: {labelKey: 'work_date', minWidth: '90px', width: '90px', maxChar: 10},
    [FIELDS.DURATION]: {labelKey: 'duration', minWidth: '50px', width: '50px'},
    [FIELDS.MATTER_NUMBER]: {labelKey: 'matter_number', maxChar: 24},
    [FIELDS.PHASE]: {labelKey: 'phase'},
    [FIELDS.TASK]: {labelKey: 'task'},
    [FIELDS.ACTIVITY]: {labelKey: 'activity'},
    [FIELDS.FF_TASK]: {labelKey: 'ff_task'},
    [FIELDS.FF_ACT]: {labelKey: 'ff_act'},
    [FIELDS.NARRATIVE]: {labelKey: 'narrative', minWidth: 'calc(100% - 102px)'}
};

function BulkEntriesView() {
    const rootStore = useContext(RootStoreContext);
    const bulkEntriesListRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation('timeentries');
    const {
        bulkEntries,
        filteredBulkEntries,
        paginatedBulkEntries,
        invalidRows,
        expandedIds,
        isBulkEntriesViewOpen,
        showOnlyInvalidRows,
        currentPage,
        entriesPerPage,
        loading,
        setFieldsKeys,
        setPageNum,
        setEntriesPerPage,
        closeBulkEntriesView,
        submitBulkEntries,
        toggleOnlyShowInvalidRows
    } = rootStore.bulkUploadStore;

    useEffect(() => {
        const { EpochConfigWorkLocaleEnabled, EpochConfigActionCodesRequired } = rootStore.appStore.features;
        if (EpochConfigWorkLocaleEnabled) {
            FIELDS_PROPS[FIELDS.WORK_LOCALE] = {labelKey: 'work_locale'};
        }
        if (EpochConfigActionCodesRequired)  {
            FIELDS_PROPS[FIELDS.ACTION_CODE] = {labelKey: 'action_code'};
        }
        if (rootStore.appStore.isProject) {
            FIELDS_PROPS[FIELDS.BILLABLE] = {labelKey: 'billable'};
        }
        setFieldsKeys();
    }, []);

    const changeEntriesPerPage = (n: number) => {
        setEntriesPerPage(n);
        changePage(1);
    }

    const changePage = (pageNum: number) => {
        setPageNum(pageNum);
        bulkEntriesListRef.current!.scrollTop = 0;
    }

    const handleClose = async () => {
        const confirm = await rootStore.confirmStore.confirm('dialog.confirm.message.cancel');
        if (confirm) {
            closeBulkEntriesView();
        }
    }

    return (
        <Dialog
            open={isBulkEntriesViewOpen}
            onClose={closeBulkEntriesView}
            disableEscapeKeyDown={true}
            fullScreen={true}
        >
            <AppBar style={{ position: 'sticky'}}>
                <Toolbar>
                    <Button color="inherit" onClick={handleClose}>
                        {t('close', { ns: 'common' })}
                    </Button>
                    <Tooltip title={invalidRows.size > 0 ? t('bulk_upload.view.header.action.submit.disabled.tooltip') : ''}>
                        <span>
                            <Button 
                                color="inherit"
                                onClick={submitBulkEntries}
                                disabled={invalidRows.size > 0}
                            >
                                {t('bulk_upload.view.header.action.submit.label')}
                            </Button>
                        </span>
                    </Tooltip>
                    <Styled.HeaderTitle variant="h6">
                        {t('bulk_upload.view.header.title')}
                    </Styled.HeaderTitle>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showOnlyInvalidRows}
                                onChange={toggleOnlyShowInvalidRows}
                                name="toggle_show"
                                disabled={invalidRows.size === 0}
                            />
                        }
                        label={t('bulk_upload.view.header.checkbox.show_unvalid')}
                    />
                </Toolbar>
            </AppBar>
            <Styled.SubHeaderContainer>
                <Styled.SubHeaderText>
                    <span>{`${t('bulk_upload.view.sub_header.total')} ${bulkEntries.length}`}</span>
                    <span>{`${t('bulk_upload.view.sub_header.invalid')} ${invalidRows.size}`}</span>
                </Styled.SubHeaderText>
                <Pagination
                    entriesPerPage={entriesPerPage}
                    currentPage={currentPage}
                    entriesLength={filteredBulkEntries.length}
                    changeEntriesPerPage={changeEntriesPerPage}
                    onPageChange={changePage}
                />
            </Styled.SubHeaderContainer>
            <Styled.ListContainer innerRef={bulkEntriesListRef}>
                {paginatedBulkEntries.map((obj, index) =>
                    <BulkEntry 
                        key={index}
                        data={obj}
                        expanded={expandedIds.has(obj.entry[FIELDS.ROW])}
                    />
                )}
                {loading && LoadingDiv(t('bulk_upload.view.header.action.submit.loading'), 0.5)}
            </Styled.ListContainer>
        </Dialog>
    );
}

export default observer(BulkEntriesView);