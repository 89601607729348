// tslint:disable:max-line-length
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import HomeStore, { AggregateTotals } from 'store/home.store';
import * as Styled from './styled';
import { arrowStyles } from './styled';
import GridView from '../GridView';
import Calendar from 'components/Calendar';
import { DateTime } from 'luxon';
import { getStartOf, Mode } from 'components/Calendar/Calendar';
import {
    CircularProgress,
    Tooltip,
    withStyles
} from '@material-ui/core';
import InfoBar from 'components/InfoBar';
import TimeEntryList from 'components/TimeEntryList/TimeEntryList';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import ImmutableTimeEntry from '../../api/immutables/ImmutableTimeEntry';
import TimerStore from 'store/Timer/timer.store';
import TimeCastSegmentDragSourceLayer from 'components/DragNDropTimeCastSegment/SegmentDragSourceList';
import NewTimeEntryDropTarget from 'components/DragNDropTimeCastSegment/NewTimeEntryDropTarget';
import { getDateFormat } from '../../util/date';
import { FabContainerView } from 'components/FabContainer/FabContainerView';
import { HomeAppBar } from 'components/HomeAppBar/HomeAppBar';
import SegmentsView from './SegmentsView';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { TKConsumer } from 'common/TKProvider';
import { Features, TimeKeeperAssignment } from '../../api/types/types';
import { withTranslation } from 'react-i18next';
import { i18n } from 'i18next';

interface Props {
    homeStore?: HomeStore;
    timerStore?: TimerStore;
    // tslint:disable:no-any
    classes?: any; // withStyles()
    t: any;
    // tslint:enable:no-any
    i18n: i18n;
}

interface State {
    singleClickTimer: NodeJS.Timer | null;
    // tslint:disable-next-line:no-any
    arrowRef: any;
    toggleTCView: boolean;
}

export interface MatterTotal {
    name: string;
    hrs: number;
}

export interface Total {
    'totals': AggregateTotals;
    'mattertotals': MatterTotal[];
}

export type Totals = Map<string, Total>;

export enum ViewMode {
    MONTH = 'MONTH',
    WEEK = 'WEEK',
    DAY = 'DAY',
    GRID = 'GRID'
}

const TotalsTable = (totals: AggregateTotals) => {
    return (
        <Styled.CellBottom>
            <Styled.TotalsContainer>
                <Styled.TotalsLineContainer>
                    <Styled.Posted>{totals.posted.toFixed(2)}</Styled.Posted>
                    <div style={{margin: '0 3px'}}>,</div>
                    <Styled.Draft>{totals.draft.toFixed(2)}</Styled.Draft>
                </Styled.TotalsLineContainer>
                <Styled.TotalsLineContainer>
                    <Styled.Billable>{totals.billable.toFixed(2)}</Styled.Billable>
                    <div style={{margin: '0 3px'}}>,</div>
                    <Styled.NonBillable>{totals.nonbillable.toFixed(2)}</Styled.NonBillable>
                </Styled.TotalsLineContainer>
            </Styled.TotalsContainer>
        </Styled.CellBottom>
    )
}
export const LoadingDiv = (message?: string, alpha: number = 0.1, disableShrink: boolean = false) => {
    return (
        <Styled.Loading alpha={alpha}>
            <Styled.LoadingContainer>
                <CircularProgress size={100} disableShrink={disableShrink}/>
                {message && <Styled.LoadingMessage>{message}</Styled.LoadingMessage>}
            </Styled.LoadingContainer>
        </Styled.Loading>
    )
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        homeStore: rootStore.homeStore,
        timerStore: rootStore.timerStore
    }
})
@observer
class Home extends React.Component<Props, State> {
    private static MAX_LINES_SHOWN_IN_DAY_HOVER_TOOLTIP: number = 5;

    state = {
        singleClickTimer: null,
        arrowRef: null,
        toggleTCView: JSON.parse(localStorage.getItem('toggletcview') || 'true')
    }

    // tslint:disable-next-line:no-any
    handleArrowRef = (node: any) => {
        if (node) {
            this.setState({ arrowRef: node });
        }
    }
    toggleTimeCastView = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ toggleTCView: event.target.checked });
        localStorage.setItem('toggletcview', JSON.stringify(event.target.checked));
    }

    async componentDidMount() {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1;
        const day = now.getDate();
        const today = DateTime.local(year, month, day);
        this.props.homeStore!.rootStore.timerStore!.loadTimers();
        await this.props.homeStore!.fetchTimeCastSettings();
        this.props.homeStore!.debouncedRefreshSegments();
        this.props.homeStore!.setToday(today, true);
        this.props.homeStore!.setToggleTimers(false);
        this.props.homeStore!.setTimeEntryRange(today);
        this.props.homeStore!.checkPushNotsAndSync();
        this.props.homeStore!.shouldRefreshTCSegments(true);
    }
    componentWillUnmount(): void {
        this.props.homeStore!.shouldRefreshTCSegments(false);
    }

    getCalendarText = () => {
        const {
            calendarMode,
            mainCalendarDate,
            rootStore
        } = this.props.homeStore!;
        switch (calendarMode) {
            case ViewMode.MONTH:
                return mainCalendarDate.toFormat('LLLL y');
            case ViewMode.WEEK:
            case ViewMode.GRID:
                const start = getStartOf(
                    mainCalendarDate,
                    'week',
                    rootStore.appStore.startOfWeek
                );
                return `${start.toFormat(getDateFormat())}
                    - ${start.plus({days: 6}).toFormat(getDateFormat())}`
            default:
                return mainCalendarDate.toFormat(getDateFormat());
        }
    }

    onDoubleClick = (date: DateTime) => {
        this.props.homeStore!.setSelectedDates([date]);
        this.props.homeStore!.changeViewMode(ViewMode.DAY);
    }

    createEntry = (dateStr: string) => {
        let date = DateTime.fromISO(dateStr);
        this.createNewTimeEntry([date]);
    }

    editEntry = async (t: TimeEntry) => {
        await this.props.homeStore!.loadEntry(t.clone());
        this.props.homeStore!.dayCountsIfDayOrWeek();
    }

    copyTimeEntry = async (t: TimeEntry) => {
        await this.props.homeStore!.copyTimeEntry(t);
        this.props.homeStore!.dayCountsIfDayOrWeek();
    }

    deleteEntry = async (t: TimeEntry) => {
        await this.props.homeStore!.deleteEntries([t]);
        this.props.homeStore!.dayCountsIfDayOrWeek();
        this.props.homeStore!.setTimersForDay();
        this.props.homeStore!.debouncedRefreshSegments();
    }

    deleteSelectedEntries = async () => {
        await this.props.homeStore!.deleteSelectedEntries();
        this.props.homeStore!.dayCountsIfDayOrWeek();
        this.props.homeStore!.setTimersForDay();
        this.props.homeStore!.debouncedRefreshSegments();
    }

    moveSelectedEntries = async () => {
        await this.props.homeStore!.moveSelectedEntries();
        this.props.homeStore!.dayCountsIfDayOrWeek();
    }

    mergeEntries = async () => {
        await this.props.homeStore!.mergeEntries();
        this.props.homeStore!.dayCountsIfDayOrWeek();
    }
    saveEntry = (t: TimeEntry) => {
        this.props.homeStore!.saveEntries([t]);
    }
    postEntry = async (t: TimeEntry) => {
        let expandedIds = this.props.homeStore!.expandedEntryIds;
        if (!expandedIds.includes(t.id!)) {
            await this.props.homeStore!.determineCodeSets(t.id!);
        }
        this.props.homeStore!.postEntries([t.id!]);
    }
    unpostEntry = async (entry: TimeEntry) => {
        await this.props.homeStore!.unpostEntries([entry.id!]);
    }
    clearSelectedEntries = () => {
        this.props.homeStore!.setSelectedTimeEntries([]);
    }

    /**
     * Opens a dialog to create one or more time entries.
     */
    createNewTimeEntry = (dates: DateTime[]) => {
        if (!dates || !dates.length) {
            // empty array or invalid input, do nothing
            dates = [DateTime.local()];
        }

        if (dates.length > 1) {
            this.props.homeStore!.openMultipleNewTimeEntryDialog(dates);
        } else {
            this.props.homeStore!.openNewTimeEntryDialog(
                dates[0],
                this.props.homeStore!.selectedSegments,
                this.props.homeStore!.selectedTimerSegments,
                this.props.homeStore!.dayViewTimers,
                this.props.homeStore!.miniCalendarMainDate
            );
        }
    }

    onCellClick(event: React.MouseEvent<HTMLElement>, date: DateTime): void {
        const {setSelectedDates, addSelectedDate} = this.props.homeStore!;
        const delay = 250;
        if (event.altKey || event.ctrlKey) {
            addSelectedDate(date);
            return;
        } else {
            setSelectedDates([date]);
            this.setState(
                (prevState: State) => {
                    if (prevState.singleClickTimer === null) { // single click
                        // tslint:disable-next-line
                        let timer = (window as any).setTimeout(
                            () => { this.setState({ singleClickTimer: null }); },
                            delay
                        );
                        return { singleClickTimer: timer };
                    } else { // double click
                        // tslint:disable-next-line
                        (window as any).clearTimeout(this.state.singleClickTimer!);
                        this.onDoubleClick(date);
                        return { singleClickTimer: null };
                    }
                }
            );
        }
    }

    CalendarCell = (date: DateTime) => {
        const { t, homeStore, classes } = this.props;
        const {
            mainCalendarDate,
            selectedDates,
            getAggregateTotalsFor,
            getMetaDataForDay,
            calendarMode,
            isToday
        } = homeStore!;

        const metaData = getMetaDataForDay(date);
        let hasEntries = false;
        let totals = [] as { name: string, hrs: number }[];
        let someEntriesAreHidden = false;
        let noHoursReported = false;

        if (metaData && metaData.matterTotals) {
            totals = metaData.matterTotals.slice(0, Home.MAX_LINES_SHOWN_IN_DAY_HOVER_TOOLTIP);
            hasEntries = totals.length > 0;
            someEntriesAreHidden = metaData.matterTotals!.length > Home.MAX_LINES_SHOWN_IN_DAY_HOVER_TOOLTIP;
            noHoursReported = metaData.matterTotals.length === 0 && metaData.entries.length > 0;
        }

        const aggregateTotals = getAggregateTotalsFor([date]);
        const matterLabel = homeStore!.rootStore.appStore.features.EpochConfigMatterLabel.toLowerCase();

        return (
            <Tooltip
                title={
                    <div>
                        {totals.map((total, index) =>
                            <div key={index}>
                                {total.name ? total.name : t('calendar.cell.tooltip.no_matter', { matterLabel })} - {Number(ImmutableTimeEntry
                                .durationToHours(total.hrs)).toFixed(2)} {t('duration.hrs')}
                            </div>
                        )}
                        {someEntriesAreHidden && (<div>{t('calendar.cell.tooltip.more_entries')}</div>)}
                        {noHoursReported && <div>{t('calendar.cell.tooltip.no_time')}</div>}
                        <span className={classes.arrowArrow} ref={this.handleArrowRef}/>
                    </div>
                }
                disableHoverListener={!hasEntries}
                classes={{popper: classes.arrowPopper}}
                PopperProps={{
                    popperOptions: {
                        modifiers: {
                            arrow: {
                                enabled: Boolean(this.state.arrowRef),
                                element: this.state.arrowRef
                            }
                        }
                    }
                }}
            >
                <Styled.DayBox
                    enabled={date.month === mainCalendarDate.month || ViewMode.MONTH !== calendarMode}
                    selected={selectedDates.findIndex((sd) => sd.startOf('day').equals(date.startOf('day'))) > -1}
                    onClick={e => {
                        if (date.month === mainCalendarDate.month || calendarMode === ViewMode.WEEK) {
                            this.onCellClick(e, date);
                        }
                    }}
                >
                    <Styled.CellTop>
                        <Styled.DateContainer
                            today={isToday(date)}
                        >
                            {date.day}
                        </Styled.DateContainer>
                        <TKConsumer>
                            {(tk: TimeKeeperAssignment) => (tk && tk.writable) &&
                                <div style={{ height: 0, overflow: 'visible' }}>
                                    <Styled.Plus
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.createNewTimeEntry([date]);
                                        }}
                                    > +
                                    </Styled.Plus>
                                </div>
                            }
                        </TKConsumer>
                    </Styled.CellTop>
                    {hasEntries && (
                        <TotalsTable
                            posted={aggregateTotals.posted}
                            draft={aggregateTotals.draft}
                            billable={aggregateTotals.billable}
                            nonbillable={aggregateTotals.nonbillable}
                        />
                    )}
                </Styled.DayBox>
            </Tooltip>
        )
    }

    MiniCalendarCell = (date: DateTime) => {
        const {isToday, miniCalendarMainDate, selectedDates} = this.props.homeStore!;

        return (
            <Styled.DayBox
                enabled={date.month === miniCalendarMainDate.month}
                selected={selectedDates.findIndex((sd) => sd.startOf('day').equals(date.startOf('day'))) > -1}
            >
                <Styled.DateContainer today={isToday(date)}>
                    {date.day}
                </Styled.DateContainer>
            </Styled.DayBox>
        );
    }

    render() {
        const {
            startDate,
            endDate,
            summaryOfAggregateTotals,
            calendarMode,
            mainCalendarDate,
            changeViewMode,
            stepMainDate,
            stepSideBarDate,
            selectedTimeEntriesMap,
            selectedEntryIds,
            expandedEntryIds,
            selectedDates,
            miniCalendarMainDate,
            validationState,
            durVstate,
            loading,
            requiredTkHours,
            toggleTimers,
            isTCViewInProgress,
            timeEntriesCount,
            setTodayEntries,
            isTimersUpdating,
            segmentsLoading,
            setFieldLoaderFn,
            matterLoading,
            rootStore
        } = this.props.homeStore!;
        const {loadTimersBool, mainWinLoading} = rootStore.timerStore!;
        const { startOfWeek } = rootStore.appStore;
        const { classes } = this.props;
        const timeEntries = [...selectedTimeEntriesMap.entries()];

        return (
            <Styled.Container direction="column">
                <FeaturesConsumer>
                    {(features: Features) => features.EpochConfigTimeCastEnabled &&
                        ((loading || segmentsLoading) && (calendarMode === ViewMode.DAY)) ? LoadingDiv() : ''
                    }
                </FeaturesConsumer>

                {(loading && (calendarMode !== ViewMode.MONTH)) ? LoadingDiv() : ''}
                <HomeAppBar
                    changeViewMode={changeViewMode}
                    calendarMode={calendarMode}
                    setToday={setTodayEntries}
                    stepMainDate={stepMainDate}
                    createNewTimeEntry={() => this.createNewTimeEntry(selectedDates)}
                    isTCViewInProgress={isTCViewInProgress}
                    timersLoading={loadTimersBool}
                    toggleTCView={this.state.toggleTCView}
                    getCalendarText={this.getCalendarText}
                    toggleTimeCastView={this.toggleTimeCastView}
                />
                <Styled.ContentContainer>
                    <Styled.MainContainer week={calendarMode === ViewMode.WEEK}>
                        {(calendarMode === ViewMode.WEEK || calendarMode === ViewMode.MONTH) &&
                        <Styled.CalendarContainer>
                            <Calendar
                                currentDate={mainCalendarDate}
                                startOfWeek={startOfWeek}
                                cellComponent={this.CalendarCell}
                                mode={
                                    calendarMode === ViewMode.MONTH
                                        ? Mode.MONTH
                                        : Mode.WEEK
                                }
                            />
                        </Styled.CalendarContainer>}
                        {calendarMode === ViewMode.GRID &&
                            <Styled.WeekDayViewContainer>
                                <GridView
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </Styled.WeekDayViewContainer>
                        }
                        {(calendarMode === ViewMode.DAY || calendarMode === ViewMode.WEEK) &&
                        <Styled.WeekDayViewContainer>
                            <TimeCastSegmentDragSourceLayer/>
                            <Styled.TimeEntryList>
                                <NewTimeEntryDropTarget/>
                                {timeEntries.length > 0 && timeEntries.slice().sort().map(([date, entries], teIndex) => (
                                    <TimeEntryList
                                        key={`te${teIndex}`}
                                        entries={entries}
                                        validationMap={validationState}
                                        durValidationMap={durVstate}
                                        date={DateTime.fromISO(date)}
                                        onChange={this.props.homeStore!.changeEntry}
                                        selected={selectedEntryIds}
                                        onSelect={this.props.homeStore!.setSelectedTimeEntries}
                                        expanded={expandedEntryIds}
                                        onExpand={this.props.homeStore!.setExpandedTimeEntries}
                                        onCreateEntry={() => this.createEntry(date)}
                                        onCancel={this.props.homeStore!.revertEntry}
                                        onEdit={this.editEntry}
                                        onCopy={this.copyTimeEntry}
                                        onDelete={this.deleteEntry}
                                        onSave={this.saveEntry}
                                        onPost={this.postEntry}
                                        onUnpost={this.unpostEntry}
                                        onSplit={this.props.homeStore!.splitEntry}
                                        onMerge={this.mergeEntries}
                                        onTransfer={this.props.homeStore!.transferEntries}
                                        buildCodeSets={this.props.homeStore!.determineCodeSets}
                                        timeCastEnabledInDayView={this.state.toggleTCView &&
                                            ((calendarMode === ViewMode.DAY) ? true : false)}
                                        onSetFieldLoader={setFieldLoaderFn}
                                    />
                                ))}
                            {isTimersUpdating && LoadingDiv()}
                            </Styled.TimeEntryList>
                            <SegmentsView
                                toggleTCView={this.state.toggleTCView}
                                classes={classes}
                            />
                        </Styled.WeekDayViewContainer>
                        }
                    </Styled.MainContainer>
                    {calendarMode !== ViewMode.GRID && <InfoBar
                        view={calendarMode}
                        currentDate={miniCalendarMainDate}
                        cellComponent={this.MiniCalendarCell}
                        mode={Mode.MONTH}
                        onNextMonth={() => stepSideBarDate(1)}
                        onPrevMonth={() => stepSideBarDate(-1)}
                        aggregateTotals={summaryOfAggregateTotals}
                        tkHours={requiredTkHours}
                        toggleExpansion={!toggleTimers}
                        timeEntriesCount={timeEntriesCount}
                    />}
                    {(calendarMode === ViewMode.DAY || calendarMode === ViewMode.WEEK) &&
                    <FabContainerView
                        dirty={this.props.homeStore!.dirty}
                        saveDirtyEntries={this.props.homeStore!.saveDirtyEntries}
                        clearSelectedEntries={this.clearSelectedEntries}
                        postSelectedEntries={this.props.homeStore!.postSelectedEntries}
                        moveSelectedEntries={this.moveSelectedEntries}
                        mergeEntries={this.mergeEntries}
                        transferEntries={this.props.homeStore!.transferEntries}
                        deleteSelectedEntries={this.deleteSelectedEntries}
                        selectedEntryIds={selectedEntryIds}
                        disableSavePost={matterLoading}
                    />}
                </Styled.ContentContainer>
                {mainWinLoading && LoadingDiv()}
            </Styled.Container>
        )
    }
}

// tslint:disable-next-line:no-any
export default withStyles(arrowStyles as any)(withTranslation(['home'])(Home));
