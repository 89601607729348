import * as React from 'react';
import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { RootStoreContext } from '../../App';

export default function BulkUploadButton() {
    const inputFileRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation('timeentries');
    const rootStore = useContext(RootStoreContext);
    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files ? e.currentTarget.files : [];
        if (files[0]) {
            const file = new File([files[0]], files[0].name, { type: files[0].type });
            e.currentTarget.value = '';
            rootStore.bulkUploadStore.init(file);
        }
    }
    
    const onBtnClick = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }
    }

    return (
        <>
            <input
                accept=".csv, .xlsx, .xls"
                style={{ display: 'none' }}
                id="upload-file"
                hidden={true}
                type="file"
                ref={inputFileRef}
                onChange={onFileChange}
            />
            <IconButton
                key={'upload'}
                title={t('header.action.bulk_upload.tooltip')}
                onClick={onBtnClick}
            >
                <CloudUpload />
            </IconButton>
        </>
    );
}
