import * as React from 'react';
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp, Delete } from '@material-ui/icons';
import { RootStoreContext } from '../../App';
import * as Styled from './styled';
import { BulkUploadData, BulkUploadEntry, BulkUploadObject, FIELDS } from 'api/types/types';
import { FlexDiv } from 'common/flex';
import { DateTime } from 'luxon';
import { FIELDS_PROPS } from './BulkEntriesView';
import { getDateFormat, getUserLocale } from 'util/date';

interface Props {
    data: BulkUploadObject;
    expanded: boolean;
}

function BulkEntry({data, expanded}: Props) {
    const { t } = useTranslation('timeentries');
    const rootStore = useContext(RootStoreContext);
    const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;
    const { entry, errors } = data;
    const [workDate, setWorkDate] = useState('');

    useEffect(() => {
        const initDate = errors[FIELDS.WORK_DATE] ?
            entry[FIELDS.WORK_DATE]
        :
            DateTime.fromISO(entry[FIELDS.WORK_DATE])
                .setLocale(getUserLocale())
                .toLocaleString();
        setWorkDate(initDate);
    }, []);

    const {
        fields,
        bulkEntryOnChange,
        removeBulkEntry,
        updateExpandedIds,
        addToInvalidRows,
        removeFromInvalidRows
    } = rootStore.bulkUploadStore;

    const handleDataChange = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value;
        if (key === FIELDS.WORK_DATE) {
            setWorkDate(val);
        } else {
            entry[key as keyof BulkUploadEntry] = val;
        }
        delete errors[key];
        delete errors.error;
        if (JSON.stringify(errors) === '{}') {
            removeFromInvalidRows(entry[FIELDS.ROW]);
        }
        bulkEntryOnChange(data);
    }

    const handleOnBlur = (key: string) => () => {
        switch (key) {
            case FIELDS.WORK_DATE:
                // get local format - replace to accept short format input 1 digit for month and day, and 2 digit for year
                const localDateFormat = getDateFormat().replace('d/', '/').replace('M/', '/').replace('/yy', '/');
                const date = DateTime.fromFormat(workDate, localDateFormat);
                if (date.isValid) {
                    entry[key] = date.toISODate();
                    setWorkDate(date.setLocale(getUserLocale()).toLocaleString());
                } else {
                    errors[key] = [`${t('bulk_upload.view.field.validation.date')}: ${localDateFormat}`];
                    addToInvalidRows(entry[FIELDS.ROW]);
                }
                bulkEntryOnChange(data);
                break;
            case FIELDS.MATTER_NUMBER:
            case FIELDS.NARRATIVE:
                if (entry[key]!.trim() === '') {
                    errors[key] = [t('bulk_upload.view.field.validation.required')];
                    addToInvalidRows(entry[FIELDS.ROW]);
                    bulkEntryOnChange(data);
                }
                break;
            case FIELDS.DURATION:
                const dur = Number(entry[key]);
                if (isNaN(dur) || dur < 0 || dur > 24) {
                    errors[key] = [t('bulk_upload.view.field.validation.duration')];
                    addToInvalidRows(entry[FIELDS.ROW]);
                    bulkEntryOnChange(data);
                }
                break;
            default:
        }
        
    }
    
    return (
        <Styled.ItemContainer
            elevation={1}
            square={true}
            error={JSON.stringify(errors) !== '{}'} 
        >
            <FlexDiv direction="column">
                {errors.error && <Styled.GeneralError>{errors.error}</Styled.GeneralError>}
                <Styled.FieldsContainer>
                    <Styled.Field
                        minWidth={FIELDS_PROPS[FIELDS.ROW].minWidth}
                        width={FIELDS_PROPS[FIELDS.ROW].width}
                        value={entry[FIELDS.ROW]}
                        label={t(`bulk_upload.view.field.${FIELDS_PROPS[FIELDS.ROW].labelKey}`)}
                        InputProps={{
                            disableUnderline: true,
                            readOnly: true,
                            inputProps: {
                                style: { cursor: 'default' }
                            }
                        }}
                    />
                    {fields.map((field: keyof BulkUploadData, index) =>
                        <Styled.Field
                            key={index}
                            fullWidth={true}
                            value={field === FIELDS.WORK_DATE ? workDate : entry[field]}
                            label={t(`bulk_upload.view.field.${FIELDS_PROPS[field].labelKey}`, { matterLabel })}
                            minWidth={FIELDS_PROPS[field].minWidth}
                            width={FIELDS_PROPS[field].width}
                            onChange={handleDataChange(field)}
                            onBlur={handleOnBlur(field)}
                            helperText={errors[field] && errors[field].join()}
                            error={errors[field] && errors[field].length > 0}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                maxLength: FIELDS_PROPS[field].maxChar ? FIELDS_PROPS[field].maxChar : 20
                            }}
                        />
                    )}
                    <Styled.ActionContainer>
                        <Tooltip title={t(`${expanded ? 'collapse' : 'expand'}`, { ns: 'common' })}>
                            <IconButton onClick={() => updateExpandedIds(entry[FIELDS.ROW])} style={{ height: '48px' }}>
                                {expanded ? <KeyboardArrowUp/> : <KeyboardArrowDown />}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('delete')}>
                            <IconButton onClick={() => removeBulkEntry(entry[FIELDS.ROW])} style={{ height: '48px'}}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Styled.ActionContainer>
                </Styled.FieldsContainer>
                {expanded &&
                    <Styled.Narrative>
                        <Styled.Field
                            value={entry[FIELDS.NARRATIVE]}
                            label={t(`bulk_upload.view.field.${FIELDS_PROPS[FIELDS.NARRATIVE].labelKey}`)}
                            minWidth={FIELDS_PROPS[FIELDS.NARRATIVE].minWidth}
                            onChange={handleDataChange(FIELDS.NARRATIVE)}
                            onBlur={handleOnBlur(FIELDS.NARRATIVE)}
                            helperText={errors[FIELDS.NARRATIVE] && errors[FIELDS.NARRATIVE].join()}
                            error={errors[FIELDS.NARRATIVE] && errors[FIELDS.NARRATIVE].length > 0}
                        />
                    </Styled.Narrative>
                }
            </FlexDiv>
        </Styled.ItemContainer>
    );
}

export default BulkEntry;